import React, { Component } from 'react';
import AppBar from '../_components/appbar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Nav from '../_components/nav';
import { Paper, Grid, Button, TextField, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { parametroAction } from '../_actions';
import { withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';
import ResponsiveDrawer from '../_components/responsiveDrawer';
import throttle from 'lodash.throttle';

const drawerWidth = 210;
const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    contentRoot: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    appFrame: {
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        //display: 'flex',
        width: '100%',
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
    },
    'appBar-left': {
        marginLeft: drawerWidth,
    },
    'appBar-right': {
        marginRight: drawerWidth,
    },
    drawerPaper: {
        position: 'relative',
        width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
    },
    gridItem: {
        padding: 5,
        alignSelf: 'flex-end'
    },
    submitButton: {
        color: '#ffffff'
    }
});
class ParametroEdit extends Component {

    state = {
        isMobile: window.innerWidth < 468
    }

    handleChange = prop => event => {
        const { dispatch } = this.props;
        dispatch(parametroAction.onChangeProps(prop, event));
    };


    componentDidMount() {
        const { match: { params } } = this.props;
        const { dispatch } = this.props;
        if (params.id) {
            dispatch(parametroAction.getParametroById(params.id));
        } else {
            dispatch(parametroAction.clear());
        }
        window.addEventListener('resize', this.throttledHandleWindowResize);
    }
    throttledHandleWindowResize = () => {
        return throttle(() => {
            this.setState({ isMobile: window.innerWidth < 468 });
            this.forceUpdate();
        }, 200);
    }
    componentWillUnmount() {
        window.addEventListener('resize', this.throttledHandleWindowResize);
    }

    handleClick(event) {

        const { match: { params } } = this.props;
        const { dispatch } = this.props;

        if (this.props.parametro.chave == null || this.props.parametro.chave == '') {
            Swal.fire({
                icon: 'warning',
                title: 'Atenção',
                text: 'Informe uma Chave válida!'
            })
        } else if (this.props.parametro.valor == null || this.props.parametro.valor == '') {
            Swal.fire({
                icon: 'warning',
                title: 'Atenção',
                text: 'Informe um Valor válido!'
            })
        } else if (this.props.parametro.descricao == null || this.props.parametro.descricao == '') {
            Swal.fire({
                icon: 'warning',
                title: 'Atenção',
                text: 'Informe uma Descrição válida'
            })
        } else {

            let payload = {
                id: params.id,
                chave: this.props.parametro.chave,
                valor: this.props.parametro.valor,
                descricao: this.props.parametro.descricao
            }

            if (params.id) {
                dispatch(parametroAction.editParametroInfo(params.id, payload));
            } else {
                dispatch(parametroAction.createParametro(payload));
            }

        }
    }

    render() {
        const { classes } = this.props;
        const { match: { params } } = this.props;
        function InsertText(props) {
            return <Typography variant="h5">{'Novo Parâmetro'}</Typography>;
        }

        function EditText(props) {
            return <Typography variant="h5">{'Editar Parâmetro'}</Typography>;
        }
        function SegHeader() {
            if (params.id) {
                return <EditText />;
            }
            return <InsertText />;
        }
        return (
            <div className={classes.root}>
                <div className={classes.appFrame} style={{ display: this.state.isMobile ? 'block' : 'flex' }}>
                    <ResponsiveDrawer />
                    <main className={classes.content}>
                        <div className={classes.toolbar} />
                        <Grid container spacing={24}>
                            <Grid item xs={12} sm={3} container justify="flex-start">
                                <SegHeader />
                            </Grid>
                            <Grid item xs={6}>
                            </Grid>
                            <Grid item xs={3} container justify="flex-end">
                            </Grid>
                        </Grid>
                        <br /><br />
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <div>
                                    <Paper className={classes.contentRoot} elevation={1}>
                                        <form className={classes.container}>
                                            <Grid container spacing={24}>
                                                <Grid item xs={12} sm={6} className={classes.gridItem}>
                                                    <TextField
                                                        id="chave"
                                                        label="Chave"
                                                        className={classes.textField}
                                                        value={this.props.parametro.chave || ''}
                                                        onChange={this.handleChange('chave')}
                                                        margin="normal"
                                                        fullWidth
                                                        inputProps={{ maxLength: 100 }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} className={classes.gridItem}>
                                                    <TextField
                                                        id="valor"
                                                        label="Valor"
                                                        className={classes.textField}
                                                        value={this.props.parametro.valor || ''}
                                                        onChange={this.handleChange('valor')}
                                                        margin="normal"
                                                        fullWidth
                                                        inputProps={{ maxLength: 100 }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} className={classes.gridItem}>
                                                    <TextField
                                                        id="descricao"
                                                        label="Descrição"
                                                        className={classes.textField}
                                                        value={this.props.parametro.descricao || ''}
                                                        onChange={this.handleChange('descricao')}
                                                        margin="normal"
                                                        fullWidth
                                                        multiline
                                                        inputProps={{ maxLength: 100 }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <br />
                                            <br />
                                            <Grid container spacing={24}>
                                                <Grid item xs={12} sm={3}>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                </Grid>
                                                <Grid item xs={12} sm={3} container justify="center">
                                                    <Grid container spacing={24}>
                                                        <Grid item xs={12} container justify="flex-end">
                                                            <Button variant="contained" color="primary" className={classes.submitButton} onClick={(event) => this.handleClick(event)}>Salvar</Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Paper>
                                </div>
                            </Grid>
                        </Grid>
                    </main>
                </div>
            </div>
        );
    }
}
ParametroEdit.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
    return state;
}

const connectedParametroEditPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(withStyles(styles)(ParametroEdit)));
export { connectedParametroEditPage as ParametroEdit };