import { crudService, authService } from '../_services/';
import { history } from '../_helpers';
import Swal from 'sweetalert2';

export const usuarioAction = {
    getUsuario,
    getUsuarioSelect,
    getUsuarioById,
    onChangeProps,
    editUsuarioInfo,
    createUsuario,
    deleteUsuarioById,
    clear,
    requestPasswordReset,
    passwordReset
};
function getUsuario() {
    return dispatch => {
        let apiEndpoint = 'usuarios';
        crudService.get(apiEndpoint)
            .then((response) => {
                dispatch(changeUsuariosList(response.data));
            }).catch((err) => {
                console.log(err);
            })
    };
}
function getUsuarioSelect() {
    return dispatch => {
        let apiEndpoint = 'usuarios';
        crudService.get(apiEndpoint)
            .then((response) => {
                //response.data.unshift({ id: 0, descricao: 'Selecione ...', url: '' });
                dispatch(changeUsuariosList(response.data));
            }).catch((err) => {
                console.log(err);
            })
    };
}
function createUsuario(payload) {
    return dispatch => {
        let apiEndpoint = 'usuarios/';
        crudService.post(apiEndpoint, payload)
            .then((response) => {
                if (response && response.request.status == 201) {
                    dispatch(createUserInfo());
                    Swal.fire({
                        icon: 'success',
                        title: 'Sucesso',
                        text: 'Usuário cadastrado com sucesso!'
                    })
                    history.push('/usuarios');
                }
                else if (response && response.request.status == 400) {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Atenção',
                        text: 'Já existe um usuário com este e-mail!'
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Erro',
                        text: 'Erro ao cadastrar usuário!'
                    })
                }
            }).catch(err => {
                Swal.fire({
                    icon: 'error',
                    title: 'Erro',
                    text: 'Erro ao cadastrar usuário!'
                })
            })
    }
}
function getUsuarioById(id) {
    return dispatch => {
        let apiEndpoint = 'usuarios/' + id;
        crudService.get(apiEndpoint)
            .then((response) => {
                dispatch(editUsuariosDetails(response.data));
            })
    };
}
function onChangeProps(props, event) {
    return dispatch => {
        dispatch(handleOnChangeProps(props, event.target.value));
    }
}
function editUsuarioInfo(id, payload) {
    return dispatch => {
        let apiEndpoint = 'usuarios/' + id;
        crudService.put(apiEndpoint, payload)
            .then((response) => {
                if (response && response.request.status == 204) {
                    dispatch(updatedUserInfo());
                    Swal.fire({
                        icon: 'success',
                        title: 'Sucesso',
                        text: 'Usuário alterado com sucesso!'
                    })
                    history.push('/usuarios');
                }
                else if (response && response.request.status == 400) {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Atenção',
                        text: 'Já existe um usuário com este e-mail!'
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Erro',
                        text: 'Erro ao alterar usuário!'
                    })
                }
            }).catch(err => {
                Swal.fire({
                    icon: 'error',
                    title: 'Erro',
                    text: 'Erro ao alterar usuário!'
                })
            })
    }
}
function deleteUsuarioById(id) {
    return dispatch => {

        Swal.fire({
            title: 'Confirmação',
            text: 'Tem certeza de que deseja excluir o registro?',
            icon: 'warning',
            buttons: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: "Não, cancelar!",
            dangerMode: true,
            heightAuto: false
        })
        .then((willDelete) => {
            if (willDelete.value) {

                let apiEndpoint = 'usuarios/' + id;
                crudService.deleteDetail(apiEndpoint)
                    .then((response) => {
                        dispatch(deleteUsuariosDetails());
                        Swal.fire({
                            icon: 'success',
                            title: 'Sucesso',
                            text: 'Usuário excluído com sucesso!'
                        })
                        dispatch(usuarioAction.getUsuario());
                    })

            }
        });

    };
}
export function changeUsuariosList(usuario) {
    return {
        type: "FETECHED_ALL_USUARIO",
        usuario: usuario
    }
}
export function clear() {
    return {
        type: "USUARIO_CLEAR",
    }
}
function requestPasswordReset(payload) {
    return dispatch => {
        let apiEndpoint = 'usuarios/request-reset-password/';
        crudService.post(apiEndpoint, payload)
            .then((response) => {
                if (response && response.request.status == 200) {
                    dispatch(createUserInfo());
                    Swal.fire({
                        icon: 'success',
                        title: 'Sucesso',
                        text: 'E-mail de recuperação enviado!'
                    })
                    history.push('/usuarios');
                }
                else if (response && response.request.status == 404) {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Atenção',
                        text: 'Não existe nehumum usuário com este e-mail!'
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Erro',
                        text: 'Erro ao recuperar senha do usuário!'
                    })
                }
            }).catch(err => {
                Swal.fire({
                    icon: 'error',
                    title: 'Erro',
                    text: 'Erro ao cadastrar usuário!'
                })
            })
    }
}
function passwordReset(payload, token) {
    return dispatch => {
        let apiEndpoint = 'usuarios/reset-password/';
        authService.postWithToken(apiEndpoint, payload, token)
            .then((response) => {
                if (response && response.request.status == 200) {
                    dispatch(createUserInfo());
                    Swal.fire({
                        icon: 'success',
                        title: 'Sucesso',
                        text: 'Senha redefinida com sucesso!'
                    })
                    history.push('/usuarios');
                }
                else if (response && response.request.status == 404) {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Atenção',
                        text: 'Não existe nehumum usuário com o e-mail informado!'
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Erro',
                        text: 'Erro ao recuperar senha do usuário!'
                    })
                }
            }).catch(err => {
                Swal.fire({
                    icon: 'error',
                    title: 'Erro',
                    text: 'Erro ao cadastrar usuário!'
                })
            })
    }
}
export function handleOnChangeProps(props, value) {
    return {
        type: "HANDLE_ON_CHANGE_USUARIO",
        props: props,
        value: value
    }
}
export function editUsuariosDetails(usuario) {
    return {
        type: "USUARIO_DETAIL",
        id: usuario._id,
        email: usuario.email,
        nome: usuario.nome,
        senha: usuario.senha,
        papel: usuario.papel,
        telefone: usuario.telefone,
        log: usuario.log
    }
}
export function updatedUserInfo() {
    return {
        type: "USUARIO_UPDATED"
    }
}
export function createUserInfo() {
    return {
        type: "USUARIO_CREATED_SUCCESSFULLY"
    }
}
export function deleteUsuariosDetails() {
    return {
        type: "DELETED_USUARIO_DETAILS"
    }
}