import React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
//import VisibleItemList from '../containers/VisibleItemList'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LogoutIcon from '@material-ui/icons/HighlightOff';
import InfoIcon from '@material-ui/icons/Info';
import FolderIcon from '@material-ui/icons/Folder';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import GroupIcon from '@material-ui/icons/Group';
import SettingsIcon from '@material-ui/icons/Settings';
import PaymentIcon from '@material-ui/icons/Payment';
import Divider from '@material-ui/core/Divider';
import { authActions } from '../_actions';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Tooltip, Toolbar, Grid, Button, Link } from '@material-ui/core';
import { history } from '../_helpers';
import MailIcon from '@material-ui/icons/Mail';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

const drawerWidth = 210;
const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    appFrame: {
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        width: '100%',
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
    },
    'appBar-left': {
        marginLeft: drawerWidth,
    },
    'appBar-right': {
        marginRight: drawerWidth,
    },
    drawerPaper: {
        position: 'relative',
        width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
    },
    icon: {
        color: theme.palette.common.black
    },

    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    closeMenuButton: {
        marginRight: 'auto',
        marginLeft: 0,
    },
    loginButton: {
        color: '#ffffff'
    }

});

class ResponsiveDrawer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mobileOpen: false
        }
    }

    logout = event => {
        const { dispatch } = this.props;
        dispatch(authActions.logout());
    }

    render() {

        const { classes } = this.props;

        const handleDrawerToggle = event => {
            this.setState({ mobileOpen: !this.state.mobileOpen });
        };

        var papel = this.props.state.authentication.user.papel;

        const drawer = (
            <div>
                <List component="nav">
                    <ListItem button component='a' onClick={() => history.push("/")}>
                        <ListItemIcon>
                            <ArrowBackIcon />
                        </ListItemIcon>
                        <ListItemText primary="Painel de Visualização" />
                    </ListItem>
                    <Divider variant="middle" />
                    {/*<ListItem button component='a' onClick={() => history.push("/home")}>
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Home" />
                    </ListItem>*/}
                    <ListItem button component='a' onClick={() => history.push("/minhasestacoes")}>
                        <ListItemIcon>
                            <FolderSharedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Minhas Estações" />
                    </ListItem>
                    {papel === 'admin' ?
                        <ListItem button component='a' onClick={() => history.push("/estacoes")}>
                            <ListItemIcon>
                                <FolderIcon color="inherit" />
                            </ListItemIcon>
                            <ListItemText primary="Estações" />
                        </ListItem>
                        : null}
                    {papel === 'admin' ?
                        <ListItem button component='a' onClick={() => history.push("/usuarios")}>
                            <ListItemIcon>
                                <GroupIcon />
                            </ListItemIcon>
                            <ListItemText primary="Usuários" />
                        </ListItem>
                        : null}
                    {papel === 'admin' ?
                        <ListItem button component='a' onClick={() => history.push("/parametros")}>
                            <ListItemIcon>
                                <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Parâmetros" />
                        </ListItem>
                        : null}
                    {papel === 'admin' ?
                        <ListItem button component='a' onClick={() => history.push("/recebimentos")}>
                            <ListItemIcon>
                                <PaymentIcon />
                            </ListItemIcon>
                            <ListItemText primary="Recebimentos" />
                        </ListItem>
                        :
                        <ListItem button component='a' onClick={() => history.push("/pagamentos")}>
                            <ListItemIcon>
                                <PaymentIcon />
                            </ListItemIcon>
                            <ListItemText primary="Pagamentos" />
                        </ListItem>
                    }
                    <Divider variant="middle" />
                    <ListItem button component='a' onClick={() => history.push("/orientacoes")}>
                        <ListItemIcon>
                            <InfoIcon />
                        </ListItemIcon>
                        <ListItemText primary="Como instalar uma estação meteorológica particular" />
                    </ListItem>
                    <Divider variant="middle" />
                    <ListItem button onClick={(event) => { this.logout() }}>
                        <ListItemIcon>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                    </ListItem>
                </List>
            </div>
        );

        return (
            <div className={classes.root}>
                <CssBaseline />
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            edge="start"
                            onClick={() => handleDrawerToggle()}
                            className={classes.loginButton}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Grid container spacing={24}>
                            <Grid item xs={3} container alignContent="flex-start">
                                <Toolbar>
                                    <Link href="/">
                                        <img src={require('../imgs/logo branco png.png')} width="77px" height="43px" alt="logo" className={classes.logo} />
                                    </Link>
                                </Toolbar>
                            </Grid>
                            <Grid item xs={9} container justify="flex-end">
                                <Tooltip title="Como instalar uma estação meteorológica particular" aria-label="info">
                                    <IconButton className={classes.loginButton} onClick={() => history.push("/orientacoes")}>
                                        <InfoIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="WhatsApp para +55 (51) 9998-6571" aria-label="info">
                                    <IconButton className={classes.loginButton} onClick={() => window.open('https://wa.me/555199986571', '_blank')}>
                                        <WhatsAppIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="E-mail para contato@estacoesdobrasil.com.br" aria-label="info">
                                    <IconButton className={classes.loginButton} onClick={() => window.open("mailto:contato@estacoesdobrasil.com.br")}>
                                        <MailIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>

                <nav className={classes.drawer}>
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Hidden smUp implementation="css">
                        <Drawer
                            variant="temporary"
                            anchor={'left'}
                            open={this.state.mobileOpen}
                            onClose={() => handleDrawerToggle()}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            <IconButton onClick={() => handleDrawerToggle()} className={classes.closeMenuButton}>
                                <CloseIcon />
                            </IconButton>
                            {drawer}
                        </Drawer>
                    </Hidden>
                    <Hidden xsDown implementation="css">
                        <Drawer
                            className={classes.drawer}
                            variant="permanent"
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                        >
                            <div className={classes.toolbar} />
                            {drawer}
                        </Drawer>
                    </Hidden>
                </nav>
                <div className={classes.content}>
                    <div className={classes.toolbar} />
                    {/*<VisibleItemList / >*/}
                </div>
            </div>
        );
    }

}

ResponsiveDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
    container: PropTypes.object
};
const mapStateToProps = (state) => {
    //const { loggingIn } = state.authentication;
    return {
        state
    };
}
export default connect(mapStateToProps)(withStyles(styles)(ResponsiveDrawer));