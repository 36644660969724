import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import AppBar from '../_components/appbar';
import Nav from '../_components/nav';
import Grid from '@material-ui/core/Grid';
import ResponsiveDrawer from '../_components/responsiveDrawer';
import throttle from 'lodash.throttle';

const drawerWidth = 210;
const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    appFrame: {
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        //display: 'flex',
        width: '100%',
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
    },
    'appBar-left': {
        marginLeft: drawerWidth,
    },
    'appBar-right': {
        marginRight: drawerWidth,
    },
    drawerPaper: {
        position: 'relative',
        width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
    },
});
class Home extends Component {

    state = {
        isMobile: window.innerWidth < 468
    }

    componentDidMount() {
        window.addEventListener('resize', this.throttledHandleWindowResize);
    }
    throttledHandleWindowResize = () => {
        return throttle(() => {
            this.setState({ isMobile: window.innerWidth < 468 });
            this.forceUpdate();
        }, 200);
    }
    componentWillUnmount() {
        window.addEventListener('resize', this.throttledHandleWindowResize);
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <div className={classes.appFrame} style={{ display: this.state.isMobile ? 'block' : 'flex' }}>
                    <ResponsiveDrawer />
                    <main className={classes.content}>
                        <div className={classes.toolbar} />
                        <Grid container spacing={24}>
                            <Grid item xs={3} container justify="flex-start">
                                <Typography variant="h5"><b>{'Home'}</b></Typography>
                            </Grid>
                            <Grid item xs={6}>
                            </Grid>
                            <Grid item xs={3} container justify="flex-end">
                            </Grid>
                        </Grid>
                    </main>
                </div>
            </div>
        );
    }
}
Home.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return state;
}
const connectedHomePage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(withStyles(styles)(Home)));
export { connectedHomePage as Home };