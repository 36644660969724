import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { authActions } from '../_actions';
import { connect } from 'react-redux';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LogoutIcon from '@material-ui/icons/HighlightOff';
import InfoIcon from '@material-ui/icons/Info';
import FolderIcon from '@material-ui/icons/Folder';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import GroupIcon from '@material-ui/icons/Group';
import SettingsIcon from '@material-ui/icons/Settings';
import PaymentIcon from '@material-ui/icons/Payment';
import { history } from '../_helpers';

const drawerWidth = 240;
const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    appFrame: {
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        width: '100%',
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
    },
    'appBar-left': {
        marginLeft: drawerWidth,
    },
    'appBar-right': {
        marginRight: drawerWidth,
    },
    drawerPaper: {
        position: 'relative',
        width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
    },
    icon: {
        color: theme.palette.common.black
    }
});
class Navigation extends React.Component {
    constructor(props) {
        super(props);
    }
    logout = event => {
        const { dispatch } = this.props;
        dispatch(authActions.logout());
    }
    render() {
        const { classes } = this.props;

        var papel = this.props.state.authentication.user.papel;

        return (
            <Drawer
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper
                }}
                anchor="left"
            >
                <List component="nav">
                    <ListItem button component='a' onClick={() => history.push("/")}>
                        <ListItemIcon>
                            <ArrowBackIcon />
                        </ListItemIcon>
                        <ListItemText primary="Painel de Visualização" />
                    </ListItem>
                    <Divider variant="middle" />
                    {/*<ListItem button component='a' onClick={() => history.push("/home")}>
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Home" />
                    </ListItem>*/}
                    <ListItem button component='a' onClick={() => history.push("/minhasestacoes")}>
                        <ListItemIcon>
                            <FolderSharedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Minhas Estações" />
                    </ListItem>
                    {papel === 'admin' ?                        
                        <ListItem button component='a' onClick={() => history.push("/estacoes")}>
                            <ListItemIcon>
                                <FolderIcon color="inherit"/>
                            </ListItemIcon>
                            <ListItemText primary="Estações" />
                        </ListItem>
                        : null}  
                    {papel === 'admin' ?
                        <ListItem button component='a' onClick={() => history.push("/usuarios")}>
                            <ListItemIcon>
                                <GroupIcon />
                            </ListItemIcon>
                            <ListItemText primary="Usuários" />
                        </ListItem>
                        : null}
                    {papel === 'admin' ?
                        <ListItem button component='a' onClick={() => history.push("/parametros")}>
                            <ListItemIcon>
                                <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Parâmetros" />
                        </ListItem>
                        : null}
                    {papel === 'admin' ?
                        <ListItem button component='a' onClick={() => history.push("/recebimentos")}>
                            <ListItemIcon>
                                <PaymentIcon />
                            </ListItemIcon>
                            <ListItemText primary="Recebimentos" />
                        </ListItem>
                    :
                        <ListItem button component='a' onClick={() => history.push("/pagamentos")}>
                            <ListItemIcon>
                                <PaymentIcon />
                            </ListItemIcon>
                            <ListItemText primary="Pagamentos" />
                        </ListItem>
                    } 
                    <Divider variant="middle" />
                    <ListItem button component='a' onClick={() => history.push("/orientacoes")}>
                            <ListItemIcon>
                                <InfoIcon />
                            </ListItemIcon>
                            <ListItemText primary="Como instalar uma estação meteorológica particular" />
                    </ListItem>
                    <Divider variant="middle" />
                    <ListItem button onClick={(event) => { this.logout() }}>
                        <ListItemIcon>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                    </ListItem>
                </List>
            </Drawer>
        );
    }
}
Navigation.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
    //const { loggingIn } = state.authentication;
    return {
        state
    };
}
export default connect(mapStateToProps)(withStyles(styles)(Navigation));