import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import { visualizationAction } from '../_actions';
import PropTypes from 'prop-types';
import GoogleMap from './GoogleMap';
import { Grid, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

const mapStyles = {
    width: '100%',
    height: '100vh'
};

const drawerWidth = 240;
const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1.2,
    },
    appFrame: {
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        width: '100%',
    },
    mapFrame: {
        width: '100%',
        height: '100vh',
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex'
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
    },
    'appBar-left': {
        marginLeft: drawerWidth,
    },
    'appBar-right': {
        marginRight: drawerWidth,
    },
    drawerPaper: {
        position: 'relative',
        width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
    },
    paper: {
        position: 'absolute',
        width: theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
    },
    gridItem: {
        padding: 5,
        alignSelf: 'flex-end'
    },
    clearButton: {
        color: '#ffffff'
    }
});

// InfoWindow component
const InfoWindow = (props) => {

    const { place } = props;
    const infoWindowStyle = {
        position: 'relative',
        bottom: 150,
        left: '-45px',
        width: 220,
        backgroundColor: 'white',
        boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
        padding: 10,
        fontSize: 14,
        zIndex: 100,
    };

    return (
        <div style={infoWindowStyle}>
            <div style={{ fontSize: 12 }}>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        {place.nome}
                    </Grid>
                </Grid>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        {place.location}
                    </Grid>
                </Grid>
            </div>
            <hr />
            <div style={{ fontSize: 10 }}>
                <Grid container spacing={24}>
                    <Grid item xs={6}>
                        Temperatura
                    </Grid>
                    <Grid item xs={6}>
                        {place.temperatura}ºC
                    </Grid>
                </Grid>
                <Grid container spacing={24}>
                    <Grid item xs={6}>
                        Temperatura Máx.
                    </Grid>
                    <Grid item xs={6}>
                        {place.temperaturaMaxima}ºC
                    </Grid>
                </Grid>
                <Grid container spacing={24}>
                    <Grid item xs={6}>
                        Temperatura Mín.
                    </Grid>
                    <Grid item xs={6}>
                        {place.temperaturaMinima}ºC
                    </Grid>
                </Grid>
                <Grid container spacing={24}>
                    <Grid item xs={6}>
                        U.R.A.
                    </Grid>
                    <Grid item xs={6}>
                        {place.ura}%
                    </Grid>
                </Grid>
                <Grid container spacing={24}>
                    <Grid item xs={6}>
                        P.O.
                    </Grid>
                    <Grid item xs={6}>
                        {place.po}ºC
                    </Grid>
                </Grid>
                <Grid container spacing={24}>
                    <Grid item xs={6}>
                        Chuva
                    </Grid>
                    <Grid item xs={6}>
                        {place.chuva}mm/h
                    </Grid>
                </Grid>
                <Grid container spacing={24}>
                    <Grid item xs={6}>
                        Tot. Chuva Dia
                    </Grid>
                    <Grid item xs={6}>
                        {place.totalChuvaDia}mm
                    </Grid>
                </Grid>
                <Grid container spacing={24}>
                    <Grid item xs={6}>
                        Pressão
                    </Grid>
                    <Grid item xs={6}>
                        {place.pressao}hPa
                    </Grid>
                </Grid>
                <Grid container spacing={24}>
                    <Grid item xs={6}>
                        Rajada Máx.
                    </Grid>
                    <Grid item xs={6}>
                        {place.rajadaVentoMaxima}Km/h
                    </Grid>
                </Grid>
                <Grid container spacing={24}>
                    <Grid item xs={6}>
                        Atualizado há
                    </Grid>
                    <Grid item xs={6}>
                        {place.atualizacao}
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

// Marker component
const Marker = ({ key, show, place, color, selectedColumn }) => {

    const spanStyle = {
        display: 'table-cell',
        verticalAlign: 'middle',
        fontSize: 10,
        color: 'black'
    };

    return (
        <>
            <div id={key} key={key} style={{ backgroundColor: color, border: '1px solid black', borderRadius: '50%', height: 25, width: 25, cursor: 'pointer', zIndex: 10, display: 'table', marginLeft: '-25px', marginTop: '-25px' }}>
                <span style={spanStyle}>
                    {
                        (selectedColumn == 'temperatura' ? place.temperatura : (selectedColumn == 'temperaturaMinima' ? place.temperaturaMinima : (selectedColumn == 'temperaturaMaxima' ? place.temperaturaMaxima : null)))
                    }
                </span>
            </div>
            {show && <InfoWindow place={place} />}
        </>
    );
};

const MarkerInfoWindow = (props) => {

    const [places, setPlaces] = useState([]);
    const [, forceUpdate] = React.useState(0);
    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(visualizationAction.getVisualization());
        setPlaces(props.state.visualization.visualization.estacoes);

    }, [])

    useEffect(() => {

        setPlaces(props.state.visualization.visualization.estacoes);

    }, [props.state.visualization.visualization.estacoes])

    const onChildClickCallback = (key) => {

        //setPlaces(
        //    props.state.visualization.visualization.map(item =>
        //        item.id == key
        //            ? { ...item, show: true }
        //            : { ...item, show: false }
        //    ));

        //forceUpdate(n => !n);

    };

    const handleClose = () => {

        //setPlaces(
        //    props.state.visualization.visualization.map(item =>
        //        item.id == 0
        //            ? { ...item, show: true }
        //            : { ...item, show: false }
        //    ));

        //forceUpdate(n => !n);

    }

    function round(value, precision) {
        var multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }

    //const { places } = this.state;

    return (
        <>
            {((places != null && places.length > 0) ? (
                <div style={mapStyles}>
                    <GoogleMap
                        defaultZoom={5}
                        defaultCenter={[-15.793889, -47.882778]}
                        bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_KEY }}
                        onChildClick={onChildClickCallback}
                        onClick={handleClose}
                    >
                        {places.map((n) => {

                            if (n.latLon == null || n.latLon === '') {
                                return;
                            }

                            if (!props.selectedCidade.includes(0)) {
                                if (props.selectedCidade.length > 0 && !(props.selectedCidade.includes(n.cidadeId))) {
                                    return;
                                }
                            }

                            if (!props.selectedEstado.includes(0)) {
                                if (props.selectedEstado.length > 0 && !(props.selectedEstado.includes(n.estadoId))) {
                                    return;
                                }
                            }

                            if (props.selectedFonte !== '' && props.selectedFonte !== 0 && n.fonteId !== props.selectedFonte) {
                                return;
                            }

                            if (props.selectedSituacao !== '' && props.selectedSituacao !== 0 && n.ativa.toString() !== (props.selectedSituacao == 1 ? 'true' : 'false')) {
                                return;
                            }

                            var tempScale = ['#170D1E', '#2D1B34', '#43284D', '#543261', '#3E3061',
                                '#31326F', '#394586', '#4543A0', '#3E6391', '#487FA6', '#488CA7',
                                '#4593A0', '#4899A7', '#48A6A7', '#48A79A', '#49A781', '#49A774',
                                '#49A767', '#49A75A', '#49A744', '#51A749', '#7AA947', '#92A849',
                                '#9FA849', '#A8A449', '#A8974A', '#B38240', '#C56841', '#D05835',
                                '#DF1E1E', '#CB1C12', '#C10000', '#AA0000', '#8C0000', '#800000',
                                '#640000', '#420000', '#170000'];

                            var tempValue = n.temperatura;
                            if (props.selectedColumn == 'temperaturaMinima') {
                                tempValue = n.temperaturaMinima;
                            }
                            else if (props.selectedColumn == 'temperaturaMaxima') {
                                tempValue = n.temperaturaMaxima;
                            }

                            var scaleNumber = round((tempValue + 12) / 1.5);
                            //console.log('scaleNumberMap', scaleNumber);
                            //console.log('tempValueMap', tempValue);
                            var bgcolor = "#999";
                            var seconds = (new Date() - new Date(n.dataAtualizacao).getTime()) / 1000;

                            if (n.ativa) {
                                if (scaleNumber > (tempScale.length - 1)) {
                                    scaleNumber = (tempScale.length - 1);
                                }
                                if (scaleNumber < 0) {
                                    scaleNumber = 0;
                                }
                                bgcolor = tempScale[scaleNumber];
                            }

                            n.temperatura = parseFloat(n.temperatura).toFixed(1);
                            n.temperaturaMinima = parseFloat(n.temperaturaMinima).toFixed(1);
                            n.temperaturaMaxima = parseFloat(n.temperaturaMaxima).toFixed(1);
                            n.po = parseFloat(n.po).toFixed(1);
                            n.ventoVelocidade = parseFloat(n.ventoVelocidade).toFixed(1);

                            if (n.rajadaVentoMaxima) {
                                n.rajadaVentoMaxima = parseFloat(n.rajadaVentoMaxima).toFixed(1);
                            } else {
                                n.rajadaVentoMaxima = parseFloat(0).toFixed(1);
                            }

                            if (n.totalChuvaDia) {
                                n.totalChuvaDia = parseFloat(n.totalChuvaDia).toFixed(2);
                            } else {
                                n.totalChuvaDia = parseFloat(0).toFixed(2);
                            }

                            var location = '';
                            if (n.uf != null && n.cidadeNome != null) {
                                location = n.cidadeNome + ', ' + n.uf;
                            } else if (n.uf != null) {
                                location = n.uf;
                            } else if (n.cidadeNome != null) {
                                location = n.cidadeNome;
                            }

                            var nome = (n.apelido ? n.apelido : n.nome);
                            if (nome.length > 25) {
                                nome = nome.substring(0, 25);
                            }

                            function displayTime(seconds) {
                                const format = val => `${Math.floor(val)}`.slice(-2)
                                const hours = seconds / 3600
                                const minutes = (seconds % 3600) / 60

                                if (format(hours) != '0' && format(minutes) != '0' && format(seconds) != '0') {
                                    return format(hours) + 'h ' + format(minutes) + 'm ' + format(seconds % 60) + 's';
                                } else if (format(minutes) != '0' && format(seconds) != '0') {
                                    return format(minutes) + 'm ' + format(seconds % 60) + 's';
                                } else if (format(seconds) != '0') {
                                    return format(seconds % 60) + 's';
                                } else {
                                    return '-';
                                }

                            }

                            let objPlace = {
                                nome: nome,
                                location: location,
                                temperatura: parseFloat(n.temperatura),
                                temperaturaMinima: n.temperaturaMinima,
                                temperaturaMaxima: n.temperaturaMaxima,
                                ura: n.ura,
                                po: n.po,
                                chuva: n.chuva,
                                totalChuvaDia: n.totalChuvaDia,
                                pressao: n.pressao,
                                rajadaVentoMaxima: n.rajadaVentoMaxima,
                                atualizacao: displayTime(seconds)
                            }

                            return (
                                <Marker
                                    id={n.id}
                                    key={n.id}
                                    lat={n.latLon.x}
                                    lng={n.latLon.y}
                                    show={n.show || false}
                                    place={objPlace}
                                    color={bgcolor}
                                    selectedColumn={props.selectedColumn}
                                />
                            )

                        })}
                    </GoogleMap>
                </div>
            )
                :
                null)}
        </>
    );

}

InfoWindow.propTypes = {
    place: PropTypes.shape({
        nome: PropTypes.string,
        location: PropTypes.string,
        temperatura: PropTypes.number,
        temperaturaMinima: PropTypes.string,
        temperaturaMaxima: PropTypes.string,
        ura: PropTypes.number,
        po: PropTypes.number,
        chuva: PropTypes.string,
        totalChuvaDia: PropTypes.string,
        pressao: PropTypes.number,
        rajadaVentoMaxima: PropTypes.string,
        atualizacao: PropTypes.string
    }).isRequired,
};

Marker.propTypes = {
    show: PropTypes.bool.isRequired,
    place: PropTypes.shape({
        nome: PropTypes.string,
        location: PropTypes.string,
        temperatura: PropTypes.number,
        temperaturaMinima: PropTypes.string,
        temperaturaMaxima: PropTypes.string,
        ura: PropTypes.number,
        po: PropTypes.string,
        chuva: PropTypes.number,
        totalChuvaDia: PropTypes.string,
        pressao: PropTypes.number,
        rajadaVentoMaxima: PropTypes.string,
        atualizacao: PropTypes.string
    }).isRequired,
};

MarkerInfoWindow.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
    return {
        state
    };
}
const connectedMarkerInfoWindowPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(withStyles(styles)(MarkerInfoWindow)));
export { connectedMarkerInfoWindowPage as MarkerInfoWindow };