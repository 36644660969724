import React, { Component } from 'react';
import './App.css';
import { Router, Switch, Route } from 'react-router-dom';
import { Login } from './login/';
import { Cadastro } from './login/';
import { PasswordReset } from './login/';
import { RequestPasswordReset } from './login/';
import { Home } from './home/';
import { Usuario } from './usuarios/';
import { UsuarioEdit } from './usuarios/';
import { Estacao } from './estacoes/';
import { MinhaEstacao } from './estacoes/';
import { EstacaoEdit } from './estacoes/';
import { Pagamento } from './pagamentos/';
import { Parametro } from './parametros/';
import { ParametroEdit } from './parametros/';
import { Recebimento } from './recebimentos/';
import { Visualization } from './visualization/';
import { Orientacao } from './orientacoes/';
import { history } from './_helpers';
import { PrivateRoute } from './_components';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

require('dotenv').config({ path: __dirname + '/.env' })

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#3c5186',
            contrastText: '#000000'
        },
        secondary: {
            main: '#939393',
            contrastText: '#000000'
        }
    },
    typography: {
        fontFamily: [
            'Nunito',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif'
        ].join(',')
    }
});

class App extends Component {
    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <div className="App">
                    <Router history={history}>
                        <div>
                            <Switch>
                                <PrivateRoute exact path='/home' component={Home} />
                                <Route exact path='/login' component={Login} />
                                <Route exact path='/cadastro' component={Cadastro} />
                                <Route exact path='/password-reset' component={PasswordReset} />
                                <Route exact path='/request-password-reset' component={RequestPasswordReset} />
                                <PrivateRoute exact path='/usuarios' component={Usuario} />
                                <PrivateRoute exact path='/usuario' component={UsuarioEdit} />
                                <PrivateRoute exact path='/usuario/:id' component={UsuarioEdit} />
                                <PrivateRoute exact path='/minhasestacoes' component={MinhaEstacao} />
                                <PrivateRoute exact path='/estacoes' component={Estacao} />
                                <PrivateRoute exact path='/estacao' component={EstacaoEdit} />
                                <PrivateRoute exact path='/estacao/:id' component={EstacaoEdit} />
                                <PrivateRoute exact path='/pagamentos' component={Pagamento} />
                                <PrivateRoute exact path='/parametros' component={Parametro} />
                                <PrivateRoute exact path='/parametro' component={ParametroEdit} />
                                <PrivateRoute exact path='/parametro/:id' component={ParametroEdit} />
                                <PrivateRoute exact path='/recebimentos' component={Recebimento} />
                                <Route exact path='/orientacoes' component={Orientacao} />
                                <Route exact path='/visualization'Visualization component={Visualization} />
                                <Route exact path='/' component={Visualization} />
                            </Switch>
                        </div>
                    </Router>
                </div>
            </MuiThemeProvider>
        );
    }
}
export default App;