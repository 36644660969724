import { crudService } from '../_services/';
import { history } from '../_helpers';
import Swal from 'sweetalert2';

export const checkoutActions = {
    createPreference
};
function createPreference(lotes) {
    return dispatch => {
        let apiEndpoint = 'checkout';

        crudService.post(apiEndpoint, lotes)
            .then(response => {
                console.log('response', response);
                if (response.status == 202 || response.statusText == 'Accepted') {
                    Swal.fire({
                        icon: 'success',
                        title: 'Sucesso',
                        text: 'Teste iniciado com sucesso!'
                    }).then(function () {
                        window.location = "/minhasestacoes";
                    });
                } else {
                    window.location.replace(response.data.preference.initPoint);
                    //window.location.replace(response.data.preference.sandboxInitPoint);
                }
            }).catch(err => {
                //toast.error('Opss! Problema ao autenticar usuário! 😥')
                // err.response.data.errors.forEach(error => toast.error('Opss! ' + error))
            })
    };
}