import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import { history } from '../_helpers';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { withStyles } from '@material-ui/core/styles';
import { usuarioAction } from '../_actions';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
        width: theme.spacing(9),
        height: theme.spacing(9),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        color: '#ffffff'
    },
    backButton: {
        marginTop: theme.spacing(1),
    }
});

class PasswordReset extends Component {

    state = {
        submitted: false,
        email: '',
        token: '',
        password: '',
        repeatPassword: '',
    }

    componentDidMount() {

        const query = new URLSearchParams(this.props.location.search);
        var email = query.toString().substr(6, (query.toString().indexOf("&", 0)) - 6);
        this.setState({ email: decodeURIComponent(email)});
        console.log('email', decodeURIComponent(email));

        var token = query.toString().substr(query.toString().indexOf("token=", 0) + 6, (query.toString().length - query.toString().indexOf("token=", 0) - 6));
        this.setState({ token: token });

        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.password) {
                return false;
            }
            return true;
        });

    }

    handleChangePassword = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    handleChangeRepeatPassword = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    handleSubmit = (event) => {
        this.setState({ submitted: true }, () => {
            setTimeout(() => this.setState({ submitted: false }), 2500);
        });
        const { dispatch } = this.props;
        let payload = {
            username: this.state.email,
            password: this.state.password
        }
        console.log('payload', payload);
        dispatch(usuarioAction.passwordReset(payload, this.state.token));
    }

    render() {
        const { submitted } = this.state;
        const { classes } = this.props;

        return (
            <Container component={Paper} maxWidth="sm">
                <CssBaseline />
                <div className={classes.paper}>
                    <Grid container justify="flex-start">
                        <IconButton className={classes.backButton} onClick={() => history.goBack()} aria-label="Voltar" color="primary">
                            <KeyboardBackspaceIcon />
                        </IconButton>
                    </Grid>
                    <Typography component="h2" variant="h6">
                        Informe e confirme a nova senha.
                    </Typography>
                    <ValidatorForm className={classes.form}
                        ref="form"
                        onSubmit={this.handleSubmit}
                    >
                        <Grid container spacing={2}>                            
                            <Grid item xs={12} sm={12}>
                                <TextValidator
                                    variant="outlined"
                                    fullWidth
                                    name="senha"
                                    label="Nova Senha *"
                                    type="password"
                                    value={this.state.password}
                                    onChange={this.handleChangePassword('password')}
                                    id="senha"
                                    validators={['required']}
                                    errorMessages={['Opss! Você esqueceu da senha.']}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextValidator
                                    variant="outlined"
                                    fullWidth
                                    name="repeatPassword"
                                    label="Repita a Nova Senha *"
                                    type="password"
                                    value={this.state.repeatPassword}
                                    onChange={this.handleChangeRepeatPassword('repeatPassword')}
                                    id="repeatPassword"
                                    validators={['isPasswordMatch', 'required']}
                                    errorMessages={['As senhas não são iguais!','Opss! Você esqueceu da senha.']}
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={submitted}
                            className={classes.submit}
                        >
                            {
                                (submitted && 'Enviando informações...')
                                || (!submitted && 'Alterar')
                            }
                        </Button>
                    </ValidatorForm>
                </div>
            </Container>
        );
    }
}
PasswordReset.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return state;
}
const connectedPasswordResetPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(withStyles(styles)(PasswordReset)));
export { connectedPasswordResetPage as PasswordReset };