import { connect } from 'react-redux';
import { usuarioAction } from '../_actions';
import React, { Component } from 'react';
import AppBar from '../_components/appbar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Nav from '../_components/nav';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Grid, Button, TableContainer } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import { withRouter } from 'react-router-dom';
import { history } from '../_helpers';
import { phoneMask } from '../utils';
import ResponsiveDrawer from '../_components/responsiveDrawer';
import throttle from 'lodash.throttle';

const drawerWidth = 210;
const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    appFrame: {
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        //display: 'flex',
        width: '100%',
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
    },
    'appBar-left': {
        marginLeft: drawerWidth,
    },
    'appBar-right': {
        marginRight: drawerWidth,
    },
    drawerPaper: {
        position: 'relative',
        width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
    },
    paper: {
        position: 'absolute',
        width: theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
    },
    newButton: {
        color: '#ffffff'
    }
});
class Usuario extends Component {

    state = {
        isMobile: window.innerWidth < 468
    }
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(usuarioAction.getUsuario());
        window.addEventListener('resize', this.throttledHandleWindowResize);
    }
    throttledHandleWindowResize = () => {
        return throttle(() => {
            this.setState({ isMobile: window.innerWidth < 468 });
            this.forceUpdate();
        }, 200);
    }
    componentWillUnmount() {
        window.addEventListener('resize', this.throttledHandleWindowResize);
    }
    handleClick = (event, id) => {
        const { dispatch } = this.props;
        dispatch(usuarioAction.deleteUsuarioById(id));
    };
    render() {

        const { classes } = this.props;
        const { usuario } = this.props.usuario;

        return (
            <div className={classes.root}>
                <div className={classes.appFrame} style={{ display: this.state.isMobile ? 'block' : 'flex' }}>
                    <ResponsiveDrawer />
                    <main className={classes.content}>
                        <div className={classes.toolbar} />
                        <Grid container spacing={24}>
                            <Grid item xs={3} container justify="flex-start">
                                <Typography variant="h5"><b>{'Usuários'}</b></Typography>
                            </Grid>
                            <Grid item xs={6}>
                            </Grid>
                            <Grid item xs={3} container justify="flex-end">
                                <Button variant="contained" color="primary" className={classes.newButton} component='a' onClick={() => history.push(`/usuario`)}>NOVO</Button>
                            </Grid>
                        </Grid>
                        <br /><br />
                        <Grid container spacing={24}>
                            <TableContainer component={Paper}>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nome</TableCell>
                                            <TableCell>E-mail</TableCell>
                                            <TableCell>Telefone</TableCell>
                                            <TableCell>Ações</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                        usuario.map(n => {
                                            return (
                                                <TableRow key={n.id}>
                                                    <TableCell component="th" scope="row">
                                                        {n.nome}
                                                    </TableCell>
                                                    <TableCell>{n.email}</TableCell>
                                                    <TableCell>{phoneMask(n.telefone)}</TableCell>
                                                    <TableCell>
                                                        <IconButton className={classes.button} aria-label="Edit" component='a' onClick={() => history.push(`/usuario/${n.id}`)}>
                                                            <EditIcon className="colorPrimary" />
                                                        </IconButton>
                                                        <IconButton className={classes.button} aria-label="Delete" onClick={(event) => this.handleClick(event, n.id)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </main>
                </div>
            </div>
        );
    }
}
Usuario.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
    return {
        usuario: state.usuario
    };
}
const connectedUsuarioPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(withStyles(styles)(Usuario)));
export { connectedUsuarioPage as Usuario };