const initialState = {
    cidade: [],
    id: '',
    nome: '',
    estadoId: '',
    ibge: '',
    latLon: '',
    estado: []
};

export function cidade(state = initialState, action) {
    switch (action.type) {
        case 'FETECHED_ALL_CIDADE':
            return {
                ...state,
                cidade: action.cidade
            };
        case 'CIDADE_DETAIL':
            return {
                ...state,
                id: action.id,
                nome: action.nome,
                estadoId: action.estadoId,
                ibge: action.ibge,
                latLon: action.latLon,
                estado: action.estado
            };
        case "CIDADE_CLEAR":
            return initialState;
        case "CIDADE_UPDATED":
            return state;
        case "HANDLE_ON_CHANGE_CIDADE":
            return {
                ...state,
                [action.props]: action.value
            };
        default:
            return state
    }
}
