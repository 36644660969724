import { crudService } from '../_services/';
import { history } from '../_helpers';
import Swal from 'sweetalert2';

export const fonteAction = {
    getFonte,
    getFonteSelect,
    getFonteById,
    onChangeProps,
    editFonteInfo,
    createFonte,
    deleteFonteById,
    clear
};
function getFonte() {
    return dispatch => {
        let apiEndpoint = 'fontes';
        crudService.get(apiEndpoint)
            .then((response) => {
                dispatch(changeFontesList(response.data));
            }).catch((err) => {
                console.log(err);
            })
    };
}
function getFonteSelect() {
    return dispatch => {
        let apiEndpoint = 'fontes';
        crudService.get(apiEndpoint)
            .then((response) => {
                //response.data.unshift({ id: 0, descricao: 'Selecione ...', url: '' });
                dispatch(changeFontesList(response.data));
            }).catch((err) => {
                console.log(err);
            })
    };
}
function createFonte(payload) {
    return dispatch => {
        let apiEndpoint = 'fontes/';
        crudService.post(apiEndpoint, payload)
            .then((response) => {
                dispatch(createUserInfo());
                history.push('/fontes');
            })
    }
}
function getFonteById(id) {
    return dispatch => {
        let apiEndpoint = 'fontes/' + id;
        crudService.get(apiEndpoint)
            .then((response) => {
                dispatch(editFontesDetails(response.data));
            })
    };
}
function onChangeProps(props, event) {
    return dispatch => {
        dispatch(handleOnChangeProps(props, event.target.value));
    }
}
function editFonteInfo(id, payload) {
    return dispatch => {
        let apiEndpoint = 'fontes/' + id;
        crudService.put(apiEndpoint, payload)
            .then((response) => {
                dispatch(updatedUserInfo());
                history.push('/fontes');
            })
    }
}
function deleteFonteById(id) {
    return dispatch => {

        Swal.fire({
            title: 'Confirmação',
            text: 'Tem certeza de que deseja excluir o registro?',
            icon: 'warning',
            buttons: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: "Não, cancelar!",
            dangerMode: true,
            heightAuto: false
        })
        .then((willDelete) => {
            if (willDelete.value) {

                let apiEndpoint = 'fontes/' + id;
                crudService.deleteDetail(apiEndpoint)
                    .then((response) => {
                        dispatch(deleteFontesDetails());
                        dispatch(fonteAction.getFonte());
                    })

            }
        });

    };
}
export function changeFontesList(fonte) {
    return {
        type: "FETECHED_ALL_FONTE",
        fonte: fonte
    }
}
export function clear() {
    return {
        type: "FONTE_CLEAR",
    }
}
export function handleOnChangeProps(props, value) {
    return {
        type: "HANDLE_ON_CHANGE_FONTE",
        props: props,
        value: value
    }
}
export function editFontesDetails(fonte) {
    return {
        type: "FONTE_DETAIL",
        id: fonte._id,
        codigo: fonte.codigo,
        nome: fonte.nome,
        senha: fonte.senha,
        token: fonte.token
    }
}
export function updatedUserInfo() {
    return {
        type: "FONTE_UPDATED"
    }
}
export function createUserInfo() {
    return {
        type: "FONTE_CREATED_SUCCESSFULLY"
    }
}
export function deleteFontesDetails() {
    return {
        type: "DELETED_FONTE_DETAILS"
    }
}