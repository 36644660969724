import { crudService } from '../_services/';
import { history } from '../_helpers';

export const pagamentoAction = {
    getPagamento,
    getPagamentoUsuarioById,
    getPagamentoAtivoUsuarioById,
    clear
};
function getPagamento() {
    return dispatch => {
        let apiEndpoint = 'pagamentos';
        crudService.get(apiEndpoint)
            .then((response) => {
                dispatch(changePagamentosList(response.data));
            }).catch((err) => {
                console.log(err);
            })
    };
}
function getPagamentoUsuarioById(id) {
    return dispatch => {
        let apiEndpoint = 'pagamentos/usuario/' + id;;
        crudService.get(apiEndpoint)
            .then((response) => {
                dispatch(changePagamentosList(response.data));
            }).catch((err) => {
                console.log(err);
            })
    };
}
function getPagamentoAtivoUsuarioById(id) {
    return dispatch => {
        let apiEndpoint = 'pagamentos/usuario/ativo/' + id;;
        crudService.get(apiEndpoint)
            .then((response) => {
                dispatch(editPagamentosDetails(response.data));
            }).catch((err) => {
                console.log(err);
            })
    };
}
export function changePagamentosList(pagamento) {
    return {
        type: "FETECHED_ALL_PAGAMENTO",
        pagamento: pagamento
    }
}
export function clear() {
    return {
        type: "PAGAMENTO_CLEAR",
    }
}
function onChangeProps(props, event) {
    return dispatch => {
        dispatch(handleOnChangeProps(props, event.target.value));
    }
}
export function handleOnChangeProps(props, value) {
    return {
        type: "HANDLE_ON_CHANGE_PAGAMENTO",
        props: props,
        value: value
    }
}
export function editPagamentosDetails(pagamento) {
    return {
        type: "PAGAMENTO_DETAIL",
        id: pagamento.id,
        usuarioId: pagamento.usuarioId,
        dataPagamento: pagamento.dataPagamento,
        dataInicioValidade: pagamento.dataInicioValidade,
        dataFimValidade: pagamento.dataFimValidade,
        valor: pagamento.valor,
        formaPagamento: pagamento.formaPagamento,
        status: pagamento.status,
        transacao: pagamento.transacao,
        usuario: pagamento.usuario
    }
}