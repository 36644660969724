import { crudService } from '../_services/';
import { history } from '../_helpers';

export const estadoAction = {
    getEstado,
    getEstadoSelect,
    clear
};
function getEstado() {
    return dispatch => {
        let apiEndpoint = 'estados';
        crudService.get(apiEndpoint)
            .then((response) => {
                dispatch(changeEstadosList(response.data));
            }).catch((err) => {
                console.log(err);
            })
    };
}
function getEstadoSelect() {
    return dispatch => {
        let apiEndpoint = 'estados/select';
        crudService.get(apiEndpoint)
            .then((response) => {
                //response.data.unshift({ id: 0, nome: 'Selecione ...', uf: '', ibge: 0, paisId: 1, ddd: '', pais: null });
                dispatch(changeEstadosList(response.data));
            }).catch((err) => {
                console.log(err);
            })
    };
}
export function changeEstadosList(estado) {
    return {
        type: "FETECHED_ALL_ESTADO",
        estado: estado
    }
}
export function clear() {
    return {
        type: "ESTADO_CLEAR",
    }
}
function onChangeProps(props, event) {
    return dispatch => {
        dispatch(handleOnChangeProps(props, event.target.value));
    }
}
export function handleOnChangeProps(props, value) {
    return {
        type: "HANDLE_ON_CHANGE_ESTADO",
        props: props,
        value: value
    }
}