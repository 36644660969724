import { crudService } from '../_services/';
import { history } from '../_helpers';
import Swal from 'sweetalert2';

export const parametroAction = {
    getParametro,
    getParametroById,
    onChangeProps,
    editParametroInfo,
    createParametro,
    deleteParametroById,
    clear
};
function getParametro() {
    return dispatch => {
        let apiEndpoint = 'parametros';
        crudService.get(apiEndpoint)
            .then((response) => {
                dispatch(changeParametrosList(response.data));
            }).catch((err) => {
                console.log(err);
            })
    };
}
function createParametro(payload) {
    return dispatch => {
        let apiEndpoint = 'parametros/';
        console.log(payload);
        crudService.post(apiEndpoint, payload)
            .then((response) => {
                dispatch(createUserInfo());
                Swal.fire({
                    icon: 'success',
                    title: 'Sucesso',
                    text: 'Parâmetro cadastrado com sucesso!'
                })
                history.push('/parametros');
            })
    }
}
function getParametroById(id) {
    return dispatch => {
        let apiEndpoint = 'parametros/' + id;
        crudService.get(apiEndpoint)
            .then((response) => {
                dispatch(editParametrosDetails(response.data));
            })
    };
}
function onChangeProps(props, event) {
    return dispatch => {
        dispatch(handleOnChangeProps(props, event.target.value));
    }
}
function editParametroInfo(id, payload) {
    return dispatch => {
        let apiEndpoint = 'parametros/' + id;
        crudService.put(apiEndpoint, payload)
            .then((response) => {
                dispatch(updatedUserInfo());
                Swal.fire({
                    icon: 'success',
                    title: 'Sucesso',
                    text: 'Parâmetro alterado com sucesso!'
                })
                history.push('/parametros');
            })
    }
}
function deleteParametroById(id) {
    return dispatch => {

        Swal.fire({
            title: 'Confirmação',
            text: 'Tem certeza de que deseja excluir o registro?',
            icon: 'warning',
            buttons: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: "Não, cancelar!",
            dangerMode: true,
            heightAuto: false
        })
        .then((willDelete) => {
            if (willDelete.value) {

                let apiEndpoint = 'parametros/' + id;
                crudService.deleteDetail(apiEndpoint)
                    .then((response) => {
                        dispatch(deleteParametrosDetails());
                        Swal.fire({
                            icon: 'success',
                            title: 'Sucesso',
                            text: 'Parâmetro excluído com sucesso!'
                        })
                        dispatch(parametroAction.getParametro());
                    })

            }
        });

    };
}
export function changeParametrosList(parametro) {
    return {
        type: "FETECHED_ALL_PARAMETRO",
        parametro: parametro
    }
}
export function clear() {
    return {
        type: "PARAMETRO_CLEAR",
    }
}
export function handleOnChangeProps(props, value) {
    return {
        type: "HANDLE_ON_CHANGE_PARAMETRO",
        props: props,
        value: value
    }
}
export function editParametrosDetails(parametro) {
    return {
        type: "PARAMETRO_DETAIL",
        id: parametro._id,
        chave: parametro.chave,
        valor: parametro.valor,
        descricao: parametro.descricao
    }
}
export function updatedUserInfo() {
    return {
        type: "PARAMETRO_UPDATED"
    }
}
export function createUserInfo() {
    return {
        type: "PARAMETRO_CREATED_SUCCESSFULLY"
    }
}
export function deleteParametrosDetails() {
    return {
        type: "DELETED_PARAMETRO_DETAILS"
    }
}