import { authService } from '../_services/';
import { history } from '../_helpers';
export const authActions = {
    login,
    logout
};
function login(username, password) {
    return dispatch => {
        let apiEndpoint = 'authentication/request';
        let payload = {
            username: username,
            password: password
        }
        authService.post(apiEndpoint, payload)
            .then((response) => {
                if (response.data && response.data.token) {
                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('auth', response.data.auth);
                    localStorage.setItem('user', JSON.stringify(response.data.user));
                    dispatch(setUserDetails(response.data));
                    //history.push('/home');
                    history.push('/minhasestacoes');
                }
            })
    };
}
function logout() {
    return dispatch => {
        localStorage.removeItem('auth');
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        dispatch(logoutUser());
        history.push('/login');
    }
}
export function setUserDetails(user) {
    return {
        type: "LOGIN_SUCCESS",
        auth: user.auth,
        token: user.token,
        user: user.user
    }
}
export function logoutUser() {
    return {
        type: "LOGOUT_SUCCESS",
        auth: false,
        token: '',
        user: null
    }
}