import { crudService } from '../_services/';
import { history } from '../_helpers';
import Swal from 'sweetalert2';
import { visualizationAction } from '../_actions';

export const estacaoAction = {
    getEstacao,
    getEstacaoById,
    getEstacaoUsuarioById,
    onChangeProps,
    editEstacaoInfo,
    createEstacao,
    favEstacao,
    unfavEstacao,
    testEstacao,
    deleteEstacaoById,
    clear
};
function getEstacao() {
    return dispatch => {
        let apiEndpoint = 'estacoes';
        crudService.get(apiEndpoint)
            .then((response) => {
                dispatch(changeEstacoesList(response.data));
            }).catch((err) => {
                console.log(err);
            })
    };
}
function createEstacao(payload) {
    return dispatch => {
        let apiEndpoint = 'estacoes';
        crudService.post(apiEndpoint, payload)
            .then((response) => {
                if (response && response.request.status == 201) {
                    dispatch(createUserInfo());
                    Swal.fire({
                        icon: 'success',
                        title: 'Sucesso',
                        text: 'Estação cadastrada com sucesso!'
                    })
                    history.goBack();
                }
                else if (response && response.request.status == 400) {
                    if (response.request.responseText != null && response.request.responseText != '') {
                        Swal.fire({
                            icon: 'warning',
                            title: 'Atenção',
                            text: response.request.responseText
                        })
                    }
                    else {
                        Swal.fire({
                            icon: 'warning',
                            title: 'Atenção',
                            text: 'Já existe uma estação com este código!'
                        })
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Erro',
                        text: 'Erro ao cadastrar estação!'
                    })
                }
            }).catch(err => {
                Swal.fire({
                    icon: 'error',
                    title: 'Erro',
                    text: 'Erro ao cadastrar estação!'
                })
            })
    }
}
function favEstacao(id) {
    return dispatch => {
        let apiEndpoint = 'estacoes/favoritar/' + id;
        crudService.post(apiEndpoint)
            .then((response) => {
                if (response && response.request.status == 201) {
                    dispatch(createUserInfo());
                    dispatch(visualizationAction.getVisualization());
                }
                else
                {
                    Swal.fire({
                        icon: 'error',
                        title: 'Erro',
                        text: 'Erro ao favoritar estação!'
                    })
                }
            }).catch(err => {
                Swal.fire({
                    icon: 'error',
                    title: 'Erro',
                    text: 'Erro ao favoritar estação!'
                })
            })
    }
}
function unfavEstacao(id) {
    return dispatch => {
        let apiEndpoint = 'estacoes/desfavoritar/' + id;
        crudService.post(apiEndpoint)
            .then((response) => {
                if (response && response.request.status == 201) {
                    dispatch(createUserInfo());
                    dispatch(visualizationAction.getVisualization());
                }
                else
                {
                    Swal.fire({
                        icon: 'error',
                        title: 'Erro',
                        text: 'Erro ao favoritar estação!'
                    })
                }
            }).catch(err => {
                Swal.fire({
                    icon: 'error',
                    title: 'Erro',
                    text: 'Erro ao favoritar estação!'
                })
            })
    }
}
function testEstacao(payload) {
    return dispatch => {
        let apiEndpoint = 'estacoes/testar';
        crudService.post(apiEndpoint, payload)
            .then((response) => {
                if (response && response.status == 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Sucesso',
                        text: 'Teste realizado com sucesso. Cadastro está OK!'
                    })
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Erro',
                        text: 'Erro ao realizar teste. Cadastro não está OK!'
                    })
                }
            })
    }
}
function getEstacaoById(id) {
    return dispatch => {
        let apiEndpoint = 'estacoes/' + id;
        crudService.get(apiEndpoint)
            .then((response) => {
                dispatch(editEstacoesDetails(response.data));
            })
    };
}
function getEstacaoUsuarioById(id) {
    return dispatch => {
        let apiEndpoint = 'estacoes/usuario/' + id;
        crudService.get(apiEndpoint)
            .then((response) => {
                dispatch(changeEstacoesList(response.data));
            }).catch((err) => {
                console.log(err);
            })
    };
}
function onChangeProps(props, value) {
    return dispatch => {
        dispatch(handleOnChangeProps(props, value));
    }
}
function editEstacaoInfo(id, payload) {
    return dispatch => {
        let apiEndpoint = 'estacoes/' + id;
        crudService.put(apiEndpoint, payload)
            .then((response) => {
                if (response && response.request.status == 204) {
                    dispatch(updatedUserInfo());
                    Swal.fire({
                        icon: 'success',
                        title: 'Sucesso',
                        text: 'Estação alterada com sucesso!'
                    })
                    history.goBack();
                }
                else if (response && response.request.status == 400) {
                    if (response.request.responseText != null && response.request.responseText != '') {
                        Swal.fire({
                            icon: 'warning',
                            title: 'Atenção',
                            text: response.request.responseText
                        })
                    }
                    else {
                        Swal.fire({
                            icon: 'warning',
                            title: 'Atenção',
                            text: 'Já existe uma estação com este código!'
                        })
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Erro',
                        text: 'Erro ao alterar estação!'
                    })
                }
            }).catch(err => {
                Swal.fire({
                    icon: 'error',
                    title: 'Erro',
                    text: 'Erro ao alterar estação!'
                })
            })
    }
}
function deleteEstacaoById(id) {
    return dispatch => {

        Swal.fire({
            title: 'Confirmação',
            text: 'Tem certeza de que deseja excluir o registro?',
            icon: 'warning',
            buttons: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: "Não, cancelar!",
            dangerMode: true,
            heightAuto: false
        })
        .then((willDelete) => {
            if (willDelete.value) {

                let apiEndpoint = 'estacoes/' + id;
                crudService.deleteDetail(apiEndpoint)
                    .then((response) => {
                        dispatch(deleteEstacoesDetails());
                        Swal.fire({
                            icon: 'success',
                            title: 'Sucesso',
                            text: 'Estação excluída com sucesso!'
                        })
                        dispatch(estacaoAction.getEstacao());
                    })

            }
        });

    };
}
export function changeEstacoesList(estacao) {
    return {
        type: "FETECHED_ALL_ESTACAO",
        estacao: estacao
    }
}
export function clear() {
    return {
        type: "ESTACAO_CLEAR",
    }
}
export function handleOnChangeProps(props, value) {
    return {
        type: "HANDLE_ON_CHANGE_ESTACAO",
        props: props,
        value: value
    }
}
export function editEstacoesDetails(estacao) {
    return {
        type: "ESTACAO_DETAIL",
        id: estacao._id,
        codigo: estacao.codigo,
        nome: estacao.nome,
        senha: estacao.senha,
        token: estacao.token,
        fonteId: estacao.fonteId,
        usuarioId: estacao.usuarioId,
        fonte: estacao.fonte,
        cidadeId: estacao.cidadeId,
        cidade: estacao.cidade,
        aprovada: estacao.aprovada,
        apelido: estacao.apelido,
        link: estacao.link,
        latLon: estacao.latLon,
        latitude: (estacao.latLon ? estacao.latLon.x : ''),
        longitude: (estacao.latLon ? estacao.latLon.y : '')
    }
}
export function updatedUserInfo() {
    return {
        type: "ESTACAO_UPDATED"
    }
}
export function createUserInfo() {
    return {
        type: "ESTACAO_CREATED_SUCCESSFULLY"
    }
}
export function deleteEstacoesDetails() {
    return {
        type: "DELETED_ESTACAO_DETAILS"
    }
}