import axios from 'axios';
import config from '../config/config';
import Swal from 'sweetalert2';

export const authService = {
    post,
    postWithToken
};
function post(apiEndpoint, payload) {
    return axios.post(config.baseUrl + apiEndpoint, payload)
        .then((response) => {
            return response;
        }).catch((err) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Login inválido!'
            })
            return err;
        })
}
function postWithToken(apiEndpoint, payload, token) {

    let options = {};
    if (token) {
        options.headers = { 'Authorization': 'Bearer ' + token };
    }

    return axios.post(config.baseUrl + apiEndpoint, payload, options)
        .then((response) => {
            return response;
        }).catch((err) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Login inválido!'
            })
            return err;
        })
}