import { combineReducers } from 'redux';

import { authentication } from './auth.reducer';
import { cidade } from './cidade.reducer';
import { estacao } from './estacao.reducer';
import { estado } from './estado.reducer';
import { fonte } from './fonte.reducer';
import { pagamento } from './pagamento.reducer';
import { pais } from './pais.reducer';
import { parametro } from './parametro.reducer';
import { usuario } from './usuario.reducer';
import { visualization } from './visualization.reducer';

const rootReducer = combineReducers({
    authentication,
    cidade,
    estacao,
    estado,
    fonte,
    pagamento,
    pais,
    parametro,
    usuario,
    visualization
});

export default rootReducer;