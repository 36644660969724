import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
    Paper, FormControlLabel, Avatar, Grid, Button, TextField, Box, Typography, Link, CssBaseline,
    Checkbox, IconButton
} from '@material-ui/core';
import { connect } from 'react-redux';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { authActions } from '../_actions';
import { history } from '../_helpers';
import { withRouter } from 'react-router-dom';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Background from '../imgs/imagem1.png';

const styles = theme => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: `url(${Background})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#3c5186',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        color: '#ffffff'
    }
});

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            showPassword: false,
            submitted: false,
        }
    }

    componentDidMount() {
        if (localStorage.getItem('auth')) {
            history.push('/minhasestacoes');
        }
    }
    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    handleChangeBox = name => event => {
        this.setState({ ...this.state, [name]: event.target.checked });
    };

    handleSubmit = (event) => {
        this.setState({ submitted: true }, () => {
            setTimeout(() => this.setState({ submitted: false }), 2500);
        });
        const { username, password } = this.state;
        const { dispatch } = this.props;
        if (username && password) {
            dispatch(authActions.login(username, password));
        }

    }

    render() {
        const { formData, submitted } = this.state;
        const { classes } = this.props;
        return (
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Grid item xs={false} sm={4} md={7} className={classes.image} />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <div className={classes.paper}>
                        <Grid container justify="flex-start">
                            <IconButton className={classes.backButton} onClick={() => history.push("/")} aria-label="Voltar" color="primary">
                                <KeyboardBackspaceIcon />
                            </IconButton>
                        </Grid>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Login
              </Typography>
                        <ValidatorForm className={classes.form}
                            ref="form"
                            onSubmit={this.handleSubmit}
                        >
                            <TextValidator
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label="E-mail *"
                                value={this.state.username}
                                className={classes.textField}
                                onChange={this.handleChange('username')}
                                id="email"
                                name="email"
                                autoComplete="email"
                                validators={['required', 'isEmail']}
                                errorMessages={['Você precisa informar o e-mail', 'E-mail inválido']}
                            />
                            <TextValidator
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                value={this.state.password}
                                onChange={this.handleChange('password')}
                                name="password"
                                label="Senha *"
                                type={this.state.showPassword ? 'text' : 'password'}
                                id="password"
                                autoComplete="current-password"
                                validators={['required']}
                                errorMessages={['Opss! Você esqueceu da senha.']}
                            />
                            <Grid container justify="flex-start">
                                <FormControlLabel
                                    control={<Checkbox onChange={this.handleChangeBox('showPassword')} value='showPassword' color="primary" />}
                                    label="Mostrar senha"
                                />
                            </Grid>

                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={submitted}
                                type="submit"
                                className={classes.submit}
                            >{
                                    (submitted && 'Validando informações...')
                                    || (!submitted && 'Entrar')
                                }</Button>
                            {/*<Grid container justify="flex-start" >
                                <Grid item xs>
                                    <Link href="javascript:;" variant="body2" >
                                        {"Esqueceu a senha?"} </Link>
                                </Grid>
                            </Grid>*/}
                            <Grid item >
                                <Link href="javascript:;" onClick={() => history.push("/cadastro")} variant="body2">
                                    {"Não possui um login? Cadastre-se já!"}
                                </Link>
                            </Grid>
                            <Grid item >
                                <Link href="javascript:;" onClick={() => history.push("/request-password-reset")} variant="body2">
                                    {"Esqueceu a senha?"}
                                </Link>
                            </Grid>

                        </ValidatorForm>
                    </div>
                </Grid>
            </Grid>
        );
    }
}
Login.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    const { loggingIn } = state.authentication;
    return {
        loggingIn
    };
}
const connectedLoginPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(withStyles(styles)(Login)));
export { connectedLoginPage as Login };