import axios from 'axios';
import config from '../config/config';
import { history } from '../_helpers';

export const crudService = {
    get,
    post,
    put,
    deleteDetail
};
function get(apiEndpoint) {
    return axios.get(config.baseUrl + apiEndpoint, getOptions())
        .then((response) => {            
            return response;
        }).catch((err) => {
            console.log(err);
            if (err.response.status == 401) {
                localStorage.removeItem('auth');
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                history.push('/');
            }     
            return err;
        })
}
function post(apiEndpoint, payload) {
    return axios.post(config.baseUrl + apiEndpoint, payload, getOptions())
        .then((response) => {
            return response;
        }).catch((err) => {
            console.log(err);
            if (err.response.status == 401) {
                localStorage.removeItem('auth');
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                history.push('/');
            }
            return err;
        })
}
function put(apiEndpoint, payload) {
    return axios.put(config.baseUrl + apiEndpoint, payload, getOptions())
        .then((response) => {
            return response;
        }).catch((err) => {
            console.log(err);
            if (err.response.status == 401) {
                localStorage.removeItem('auth');
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                history.push('/');
            }    
            return err;
        })
}
function deleteDetail(apiEndpoint) {
    return axios.delete(config.baseUrl + apiEndpoint, getOptions())
        .then((response) => {
            return response;
        }).catch((err) => {
            console.log(err);
            if (err.response.status == 401) {
                localStorage.removeItem('auth');
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                history.push('/');
            }    
            return err;
        })
}
function getOptions() {
    let options = {};
    if (localStorage.getItem('token')) {
        options.headers = { 'Authorization': 'Bearer ' + localStorage.getItem('token') };
    }
    return options;
}