import { crudService, visualizationService } from '../_services/';

export const visualizationAction = {
    getVisualization
};
function getVisualization() {
    return dispatch => {
        let apiEndpoint = 'visualization';
        crudService.get(apiEndpoint)
            .then((response) => {
                dispatch(changeVisualizationsList(response.data));
            }).catch((err) => {
                console.log(err);
            })
    };
}

export function changeVisualizationsList(visualization) {
    return {
        type: "FETECHED_ALL_VISUALIZATION",
        visualization: visualization
    }
}
export function handleOnChangeProps(props, value) {
    return {
        type: "HANDLE_ON_CHANGE_VISUALIZATION",
        props: props,
        value: value
    }
}