import { connect } from 'react-redux';
import { pagamentoAction } from '../_actions';
import React, { Component } from 'react';
import AppBar from '../_components/appbar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Nav from '../_components/nav';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import Moment from 'react-moment';
import 'moment-timezone';
import ResponsiveDrawer from '../_components/responsiveDrawer';
import throttle from 'lodash.throttle';

const drawerWidth = 210;
const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    appFrame: {
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        //display: 'flex',
        width: '100%',
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
    },
    'appBar-left': {
        marginLeft: drawerWidth,
    },
    'appBar-right': {
        marginRight: drawerWidth,
    },
    drawerPaper: {
        position: 'relative',
        width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
    },
    paper: {
        position: 'absolute',
        width: theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
    },
    gridItem: {
        padding: 5,
        alignSelf: 'flex-end'
    },
    newButton: {
        color: '#ffffff'
    },
    payButton: {
        color: '#ffffff'
    }
});
class Recebimento extends Component {

    state = {
        isMobile: window.innerWidth < 468
    }
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(pagamentoAction.getPagamento());
        window.addEventListener('resize', this.throttledHandleWindowResize);        
    };
    throttledHandleWindowResize = () => {
        return throttle(() => {
            this.setState({ isMobile: window.innerWidth < 468 });
            this.forceUpdate();
        }, 200);
    }
    componentWillUnmount() {
        window.addEventListener('resize', this.throttledHandleWindowResize);
    }

    render() {

        const { classes } = this.props;
        const { pagamento } = this.props.state.pagamento;

        return (
            <div className={classes.root}>
                <div className={classes.appFrame} style={{ display: this.state.isMobile ? 'block' : 'flex' }}>
                    <ResponsiveDrawer />
                    <main className={classes.content}>
                        <div className={classes.toolbar} />
                        <Grid container spacing={24}>
                            <Grid item xs={3} container justify="flex-start">
                                <Typography variant="h5"><b>{'Recebimentos'}</b></Typography>
                            </Grid>
                            <Grid item xs={6}>
                            </Grid>
                            <Grid item xs={3} container justify="flex-end">
                            </Grid>
                        </Grid>
                        <br />
                        <Grid container spacing={24}>
                            <TableContainer component={Paper}>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Usuário</TableCell>
                                            <TableCell>Periodo</TableCell>
                                            <TableCell>Valor</TableCell>
                                            <TableCell>Recebimento</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            pagamento.map(n => {
                                                
                                                return (
                                                    <TableRow key={n.id}>
                                                        <TableCell component="th" scope="row">
                                                            {n.usuario ? n.usuario.nome : null}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {<Moment format="DD/MM/YYYY">{n.dataInicioValidade}</Moment>} a {<Moment format="DD/MM/YYYY">{n.dataFimValidade}</Moment>} 
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">R$ {n.valor.toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {n.dataPagamento ? <div>Recebido em <Moment format="DD/MM/YYYY">{n.dataPagamento}</Moment></div> : (n.status == 'A' ? <div>Em processamento</div> : <div></div>) }
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid> 
                    </main>
                </div>
            </div>
        );
    }
}
Recebimento.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
    return {
        state
    };
}
const connectedRecebimentoPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(withStyles(styles)(Recebimento)));
export { connectedRecebimentoPage as Recebimento };