import { connect } from 'react-redux';
import { pagamentoAction, checkoutActions } from '../_actions';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Table, TableBody, TableCell, TableHead, TableRow, Paper, Grid, Button, TableContainer } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Moment from 'react-moment';
import 'moment-timezone';
import ResponsiveDrawer from '../_components/responsiveDrawer';
import throttle from 'lodash.throttle';

const drawerWidth = 210;
const styles = theme => ({
    root: {
        flexGrow: 1
    },
    appFrame: {
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        //display: 'flex',
        width: '100%',
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
    },
    'appBar-left': {
        marginLeft: drawerWidth,
    },
    'appBar-right': {
        marginRight: drawerWidth,
    },
    drawerPaper: {
        position: 'relative',
        width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
    },
    paper: {
        position: 'absolute',
        width: theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
    },
    payButton: {
        color: '#ffffff'
    }
});
class Pagamento extends Component {

    constructor(props) {
        super(props);
        this.state = {
            submitted: false,
            isMobile: window.innerWidth < 468
        }
    }

    componentDidMount() {
        const { dispatch } = this.props;

        var usuarioId = this.props.state.authentication.user.id;
        dispatch(pagamentoAction.getPagamentoUsuarioById(usuarioId));

        window.addEventListener('resize', this.throttledHandleWindowResize);
    };
    throttledHandleWindowResize = () => {
        return throttle(() => {
            this.setState({ isMobile: window.innerWidth < 468 });
            this.forceUpdate();
        }, 200);
    }
    componentWillUnmount() {
        window.addEventListener('resize', this.throttledHandleWindowResize);
    }
    handleClick = (pagamento) => {
        this.setState({ submitted: true }, () => {
            setTimeout(() => this.setState({ submitted: false }), 8000);
        });
        const { dispatch } = this.props;
        dispatch(checkoutActions.createPreference(pagamento));

    };
    render() {

        const { classes } = this.props;
        const { pagamento } = this.props.state.pagamento;
        const { submitted } = this.state;

        return (
            <div className={classes.root}>
                <div className={classes.appFrame} style={{ display: this.state.isMobile ? 'block' : 'flex' }}>
                    <ResponsiveDrawer />
                    <main className={classes.content}>
                        <div className={classes.toolbar} />
                        <Grid container spacing={24}>
                            <Grid item xs={3} container justify="flex-start">
                                <Typography variant="h5"><b>{'Pagamentos'}</b></Typography>
                            </Grid>
                            <Grid item xs={6}>
                            </Grid>
                            <Grid item xs={3} container justify="flex-end">
                            </Grid>
                        </Grid>
                        <br />
                        <Grid container spacing={24}>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Periodo</TableCell>
                                            <TableCell>Valor</TableCell>
                                            <TableCell>Pagamento</TableCell>
                                            <TableCell>Ação</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            pagamento.map(n => {

                                                return (
                                                    <TableRow key={n.id}>
                                                        <TableCell component="th" scope="row">
                                                            {<Moment format="DD/MM/YYYY">{n.dataInicioValidade}</Moment>} a {<Moment format="DD/MM/YYYY">{n.dataFimValidade}</Moment>}
                                                        </TableCell>
                                                        <TableCell>{(n.valor > 0 ? 'R$ ' + n.valor.toLocaleString(navigator.language, { minimumFractionDigits: 2 }) : null)}</TableCell>
                                                        <TableCell>
                                                            {n.dataPagamento ? (n.valor > 0 ? <div> Pago em <Moment format="DD/MM/YYYY">{n.dataPagamento}</Moment></div> : <div> Teste iniciado em <Moment format="DD/MM/YYYY">{n.dataPagamento}</Moment></div>) : (n.status == 'A' ? <div>Em processamento</div> : null)}
                                                        </TableCell>
                                                        <TableCell>
                                                            {n.dataPagamento ? null : (n.status == 'A' ? <div><Button variant="contained" color="secondary" className={classes.payButton} disabled={submitted} onClick={(event) => this.handleClick(n)}>{(submitted && 'Processando ...') || (!submitted && 'Pagar novamente')}</Button></div> : <Button variant="contained" color="primary" className={classes.payButton} disabled={submitted} onClick={(event) => this.handleClick(n)}>{(submitted && 'Processando ...') || (!submitted && ((n.valor > 0 && 'Pagar') || (n.valor <= 0 && 'Testar 7 dias grátis')))}</Button>)}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </main>
                </div>
            </div>
        );
    }
}
Pagamento.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
    return {
        state
    };
}
const connectedPagamentoPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(withStyles(styles)(Pagamento)));
export { connectedPagamentoPage as Pagamento };