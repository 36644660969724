const initialState = {
    estado: [],
    id: '',
    nome: '',
    uf: '',
    ibge: '',
    paisId: '',
    ddd: '',
    pais: []
};

export function estado(state = initialState, action) {
    switch (action.type) {
        case 'FETECHED_ALL_ESTADO':
            return {
                ...state,
                estado: action.estado
            };
        case 'ESTADO_DETAIL':
            return {
                ...state,
                id: action.id,
                nome: action.nome,
                uf: action.uf,
                ibge: action.ibge,
                paisId: action.paisId,
                ddd: action.ddd,
                pais: action.pais
            };
        case "ESTADO_CLEAR":
            return initialState;
        case "ESTADO_UPDATED":
            return state;
        case "HANDLE_ON_CHANGE_ESTADO":
            return {
                ...state,
                [action.props]: action.value
            };
        default:
            return state
    }
}
