import React, { Component, PureComponent } from 'react';
import AppBar from '../_components/appbar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Nav from '../_components/nav';
import { Paper, Grid, Button, TextField, Select, Typography, Input, InputLabel, MenuItem, FormHelperText, FormControl, FormControlLabel, Switch } from '@material-ui/core';
import { connect } from 'react-redux';
import { estacaoAction, estadoAction, cidadeAction, fonteAction, usuarioAction } from '../_actions';
import { withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';
import ResponsiveDrawer from '../_components/responsiveDrawer';
import throttle from 'lodash.throttle';

const drawerWidth = 210;
const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    contentRoot: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    appFrame: {
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        //display: 'flex',
        width: '100%',
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
    },
    'appBar-left': {
        marginLeft: drawerWidth,
    },
    'appBar-right': {
        marginRight: drawerWidth,
    },
    drawerPaper: {
        position: 'relative',
        width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
    },
    gridItem: {
        padding: 5,
        alignSelf: 'flex-end'
    },
    submitButton: {
        color: '#ffffff',
        marginLeft: 5
    }
});
class EstacaoEdit extends Component {

    state = {
        isMobile: window.innerWidth < 468
    }
    handleChangeSelectedEstado(value) {
        this.props.estacao.cidade.estadoId = value;
        this.renderOptionsCidade();
        this.forceUpdate();
    }

    handleChangeSelectedCidade(value) {
        this.props.estacao.cidadeId = value;
        this.forceUpdate();
    }

    handleChangeSelectedFonte(value) {
        this.props.estacao.fonteId = value;
        this.forceUpdate();
    }

    handleChangeSelectedUsuario(value) {
        this.props.estacao.usuarioId = value;
        this.forceUpdate();
    }

    handleChange = prop => event => {
        const { dispatch } = this.props;
        dispatch(estacaoAction.onChangeProps(prop, event.target.value));
    }

    handleChangeChecked = prop => event => {
        const { dispatch } = this.props;
        dispatch(estacaoAction.onChangeProps(prop, event.target.checked));
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        this.renderOptionsEstado();

        const { dispatch } = this.props;
        if (params.id) {
            dispatch(estacaoAction.getEstacaoById(params.id));
        } else {
            dispatch(estacaoAction.clear());
        }

        dispatch(estadoAction.getEstado());
        dispatch(cidadeAction.getCidade());
        dispatch(fonteAction.getFonteSelect());
        dispatch(usuarioAction.getUsuarioSelect());

        window.addEventListener('resize', this.throttledHandleWindowResize);

    }
    throttledHandleWindowResize = () => {
        return throttle(() => {
            this.setState({ isMobile: window.innerWidth < 468 });
            this.forceUpdate();
        }, 200);
    }
    componentWillUnmount() {
        window.addEventListener('resize', this.throttledHandleWindowResize);
    }

    handleClick(event, type) {

        const { match: { params } } = this.props;
        const { dispatch } = this.props;

        if (this.props.estacao.fonteId == null || this.props.estacao.fonteId == '') {
            Swal.fire({
                icon: 'warning',
                title: 'Atenção',
                text: 'Informe uma Fonte válida!'
            })
        } else if (this.props.estacao.fonteId !== 7 && (this.props.estacao.codigo == null || this.props.estacao.codigo == '')) {
            Swal.fire({
                icon: 'warning',
                title: 'Atenção',
                text: 'Informe um Código/Station ID válido!'
            })
        } else if (this.props.estacao.fonteId == 1 && this.props.estacao.codigo.length !== 12) {
            Swal.fire({
                icon: 'warning',
                title: 'Atenção',
                text: 'Para o WeatherLink, informe um Código/Station com 12 caracteres!'
            })
        } else if (this.props.estacao.fonteId == 1 && (this.props.estacao.senha == null || this.props.estacao.senha == '')) {
            Swal.fire({
                icon: 'warning',
                title: 'Atenção',
                text: 'Informe uma Senha válida!'
            })
        } else if (this.props.estacao.fonteId == 2 && (this.props.estacao.dataValidadeToken == null || this.props.estacao.dataValidadeToken == '')) {
            Swal.fire({
                icon: 'warning',
                title: 'Atenção',
                text: 'Informe uma Data de Validade da API Key!'
            })
        } else if ((this.props.estacao.fonteId !== 3 && this.props.estacao.fonteId !== 5 && this.props.estacao.fonteId !== 9) && (this.props.estacao.token == null || this.props.estacao.token == '')) {
            Swal.fire({
                icon: 'warning',
                title: 'Atenção',
                text: 'Informe um Token/API Key válida!'
            })
        } else if (this.props.estacao.cidade.estadoId == null || this.props.estacao.cidade.estadoId == '') {
            Swal.fire({
                icon: 'warning',
                title: 'Atenção',
                text: 'Informe um Estado válido!'
            })
        } else if (this.props.estacao.cidadeId == null || this.props.estacao.cidadeId == '') {
            Swal.fire({
                icon: 'warning',
                title: 'Atenção',
                text: 'Informe uma Cidade válida!'
            })
        } else if (this.props.estacao.fonteId == 7 && (this.props.estacao.latitude == null || this.props.estacao.latitude == '')) {
            Swal.fire({
                icon: 'warning',
                title: 'Atenção',
                text: 'Informe uma Latitude!'
            })
        } else if (this.props.estacao.fonteId == 7 && (this.props.estacao.longitude == null || this.props.estacao.longitude == '')) {
            Swal.fire({
                icon: 'warning',
                title: 'Atenção',
                text: 'Informe uma Longitude!'
            })
        } else {

            let payload = {
                id: params.id,
                usuarioId: (this.props.estacao.usuarioId ? this.props.estacao.usuarioId : this.props.authentication.user.id),
                fonteId: this.props.estacao.fonteId,
                nome: this.props.estacao.nome,
                codigo: this.props.estacao.codigo,
                senha: this.props.estacao.senha,
                aprovada: this.props.estacao.aprovada,
                dataValidadeToken: this.props.estacao.dataValidadeToken,
                token: this.props.estacao.token,
                cidadeId: this.props.estacao.cidadeId,
                apelido: this.props.estacao.apelido,
                link: this.props.estacao.link,
                latLon: (this.props.estacao.fonteId == 7 ? {
                    x: this.props.estacao.latitude,
                    y: this.props.estacao.longitude
                } : null)
            }

            if (type == 'test') {
                dispatch(estacaoAction.testEstacao(payload));
            } else {
                if (params.id) {
                    dispatch(estacaoAction.editEstacaoInfo(params.id, payload));
                } else {
                    payload.id = 0;
                    dispatch(estacaoAction.createEstacao(payload));
                }
            }

        }
    }

    renderOptionsEstado() {
        return this.props.estado.estado.map((p, i) => {
            if (p.paisId === 1) {
                return (
                    <MenuItem
                        value={p.id}
                        key={i} name={p.nome}>{p.nome}
                    </MenuItem>
                );
            }
        });
    }

    renderOptionsCidade() {
        const { estadoId } = this.props.estacao.cidade;
        return this.props.cidade.cidade.map((p, i) => {
            if (p.estadoId == estadoId) {
                return (
                    <MenuItem
                        value={p.id}
                        key={i} name={p.nome}>{p.nome}
                    </MenuItem>
                );
            }
        });
    }

    renderOptionsFonte() {
        return this.props.fonte.fonte.map((p, i) => {
            return (
                <MenuItem
                    value={p.id}
                    key={i} name={p.descricao}>{p.descricao}
                </MenuItem>

            );
        });
    }

    renderOptionsUsuario() {
        return this.props.usuario.usuario.map((p, i) => {
            return (
                <MenuItem
                    value={p.id}
                    key={i} name={p.nome}>{p.nome}
                </MenuItem>

            );
        });
    }

    render() {
        const { classes } = this.props;
        const { match: { params } } = this.props;
        var papel = this.props.authentication.user.papel;

        function InsertText(props) {
            return <Typography variant="h5">{'Nova Estação'}</Typography>;
        }

        function EditText(props) {
            return <Typography variant="h5">{'Editar Estação'}</Typography>;
        }
        function SegHeader() {
            if (params.id) {
                return <EditText />;
            }
            return <InsertText />;
        }
        return (
            <div className={classes.root}>
                <div className={classes.appFrame} style={{ display: this.state.isMobile ? 'block' : 'flex' }}>
                    <ResponsiveDrawer />
                    <main className={classes.content}>
                        <div className={classes.toolbar} />
                        <Grid container spacing={24}>
                            <Grid item xs={12} sm={3} container justify="flex-start">
                                <SegHeader />
                            </Grid>
                            <Grid item xs={6}>
                            </Grid>
                            <Grid item xs={3} container justify="flex-end">
                            </Grid>
                        </Grid>
                        <br /><br />
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <div>
                                    <Paper className={classes.contentRoot} elevation={1}>
                                        <form className={classes.container}>
                                            <Grid container spacing={24}>
                                                <Grid item xs={12} sm={4} className={classes.gridItem}>
                                                    <FormControl fullWidth>
                                                        <InputLabel htmlFor="fonte">Fonte</InputLabel>
                                                        <Select
                                                            name="fonte"
                                                            value={this.props.estacao.fonteId || 0}
                                                            onChange={event => this.handleChangeSelectedFonte(event.target.value)}
                                                            input={<Input id="fonte" />}
                                                        >
                                                            {this.renderOptionsFonte()}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                {papel === 'admin' ?
                                                    <Grid item xs={12} sm={8} className={classes.gridItem}>
                                                        <FormControl fullWidth>
                                                            <InputLabel htmlFor="fonte">Usuário</InputLabel>
                                                            <Select
                                                                name="fonte"
                                                                value={this.props.estacao.usuarioId || 0}
                                                                onChange={event => this.handleChangeSelectedUsuario(event.target.value)}
                                                                input={<Input id="fonte" />}
                                                            >
                                                                {this.renderOptionsUsuario()}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    : null}
                                            </Grid>
                                            <Grid container spacing={24}>
                                                {
                                                    ((this.props.estacao.fonteId !== 7) ?
                                                        <Grid item xs={12} sm={6} className={classes.gridItem}>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="codigo"
                                                                    label={(this.props.estacao.fonteId == 4) ? "MAC Address" : "Código / Station ID"}
                                                                    className={classes.textField}
                                                                    value={this.props.estacao.codigo || ''}
                                                                    onChange={this.handleChange('codigo')}
                                                                    inputProps={{ maxLength: 17 }}
                                                                    autocomplete="anyrandomstring"
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        : null
                                                    )
                                                }
                                                {
                                                    ((this.props.estacao.fonteId == 1 || this.props.estacao.fonteId === 4 || this.props.estacao.fonteId === 6) ?
                                                        <Grid item xs={12} sm={6} className={classes.gridItem}>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="senha"
                                                                    label={((this.props.estacao.fonteId == 4) ? "Application Key" : ((this.props.estacao.fonteId == 6) ? "API Secret" : "Senha do WeatherLink"))}
                                                                    className={classes.textField}
                                                                    type={(this.props.estacao.fonteId == 4) ? "text" : "password"}
                                                                    value={this.props.estacao.senha || ''}
                                                                    onChange={this.handleChange('senha')} />
                                                            </FormControl>
                                                        </Grid>
                                                        : null
                                                    )
                                                }
                                                {
                                                    ((this.props.estacao.fonteId == 2) ?
                                                        <Grid item xs={12} sm={6} className={classes.gridItem}>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="dataValidadeToken"
                                                                    label="Data de Validade da API Key"
                                                                    className={classes.textField}
                                                                    type="date"
                                                                    value={this.props.estacao.dataValidadeToken || ''}
                                                                    onChange={this.handleChange('dataValidadeToken')} />
                                                            </FormControl>
                                                        </Grid>
                                                        : null
                                                    )
                                                }
                                            </Grid>
                                            {
                                                ((this.props.estacao.fonteId !== 3 && this.props.estacao.fonteId !== 5) ?
                                                    <Grid container spacing={24}>
                                                        <Grid item xs={12} sm={12} className={classes.gridItem}>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="token"
                                                                    label={(this.props.estacao.fonteId == 2 || this.props.estacao.fonteId == 4 || this.props.estacao.fonteId == 6) ? "API Key" : (this.props.estacao.fonteId == 7) ? "Hash" : "Token"}
                                                                    className={classes.textField}
                                                                    value={this.props.estacao.token || ''}
                                                                    onChange={this.handleChange('token')}
                                                                />
                                                            </FormControl>
                                                            <FormHelperText>{((this.props.estacao.fonteId == 2) ? "Saiba como obter a API Key no Wundergound acessando https://www.wunderground.com/member/api-keys" : ((this.props.estacao.fonteId == 6) ? "Saiba como obter a API Key e Secret no WeatherLink v2 acessando https://www.weatherlink.com/account" : ((this.props.estacao.fonteId == 7) ? "Fazer login no WeatherLink e localizar o Hash da Estação. No link de exemplo https://www.weatherlink.com/bulletin/4f3efea1-d22f-4c33-aa82-55c3b0bc4308, o Hash é 4f3efea1-d22f-4c33-aa82-55c3b0bc4308." : "")))}</FormHelperText>
                                                        </Grid>
                                                    </Grid>
                                                    : null
                                                )
                                            }
                                            <Grid container spacing={24}>
                                                <Grid item xs={12} sm={6} className={classes.gridItem}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            id="apelido"
                                                            label="Apelido"
                                                            className={classes.textField}
                                                            value={this.props.estacao.apelido || ''}
                                                            onChange={this.handleChange('apelido')}
                                                            inputProps={{ maxLength: 100 }}
                                                        />
                                                    </FormControl>
                                                    <FormHelperText>Opcional. Nome a ser exibido no lugar do nome oficial da estação.</FormHelperText>
                                                </Grid>
                                                <Grid item xs={12} sm={6} className={classes.gridItem}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            id="link"
                                                            label="Link"
                                                            className={classes.textField}
                                                            value={this.props.estacao.link || ''}
                                                            onChange={this.handleChange('link')}
                                                            inputProps={{ maxLength: 200 }}
                                                            disabled={(this.props.estacao.fonteId == 2 || this.props.estacao.fonteId == 6) ? "disabled" : ""} />
                                                    </FormControl>
                                                    <FormHelperText>Link externo para acesso à estação. Necessário apenas para o Weatherlink.</FormHelperText>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={24}>
                                                <Grid item xs={12} sm={3} className={classes.gridItem}>
                                                    <FormControl fullWidth>
                                                        <InputLabel htmlFor="estado">Estado</InputLabel>
                                                        <Select
                                                            name="estado"
                                                            value={this.props.estacao.cidade.estadoId || 0}
                                                            onChange={event => this.handleChangeSelectedEstado(event.target.value)}
                                                            input={<Input id="estado" />}
                                                        >
                                                            {this.renderOptionsEstado()}
                                                        </Select>
                                                        {/*<FormHelperText>Label + placeholder</FormHelperText>*/}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={9} className={classes.gridItem}>
                                                    <FormControl fullWidth>
                                                        <InputLabel htmlFor="cidade">Cidade</InputLabel>
                                                        <Select
                                                            name="cidade"
                                                            value={this.props.estacao.cidadeId || 0}
                                                            onChange={event => this.handleChangeSelectedCidade(event.target.value)}
                                                            input={<Input id="cidade" />}
                                                        >
                                                            {this.renderOptionsCidade()}
                                                        </Select>
                                                        {/*<FormHelperText>Label + placeholder</FormHelperText>*/}
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            {
                                                ((this.props.estacao.fonteId == 7) ?
                                                    <Grid container spacing={24}>
                                                        <Grid item xs={12} sm={6} className={classes.gridItem}>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="latLon"
                                                                    label="Latitude"
                                                                    className={classes.textField}
                                                                    value={this.props.estacao.latitude || ''}
                                                                    onChange={this.handleChange('latitude')}
                                                                />
                                                            </FormControl>
                                                            <FormHelperText>Formato:-50.4903</FormHelperText>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} className={classes.gridItem}>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    id="latLon"
                                                                    label="Longitude"
                                                                    className={classes.textField}
                                                                    value={this.props.estacao.longitude || ''}
                                                                    onChange={this.handleChange('longitude')}
                                                                />
                                                            </FormControl>
                                                            <FormHelperText>Formato:-26.2933</FormHelperText>
                                                        </Grid>
                                                    </Grid>
                                                    : null
                                                )
                                            }
                                            {papel === 'admin' ?
                                                <Grid container spacing={24}>
                                                    <Grid item xs={12} sm={12} className={classes.gridItem}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={this.props.estacao.aprovada}
                                                                    onChange={this.handleChangeChecked('aprovada')}
                                                                    value={this.props.estacao.aprovada}
                                                                    color="primary"
                                                                />
                                                            }
                                                            label="Aprovada"
                                                        />
                                                    </Grid>
                                                </Grid>
                                                : null}
                                            <br />
                                            <br />
                                            <Grid container spacing={24}>
                                                <Grid item xs={12} sm={9}>
                                                </Grid>
                                                <Grid item xs={12} sm={3} container justify="center">
                                                    <Grid container spacing={24}>
                                                        <Grid item xs={12} container justify="flex-end" >
                                                            <Button variant="contained" color="secondary" className={classes.submitButton} onClick={(event) => this.handleClick(event, 'test')}>Testar</Button>
                                                            <Button variant="contained" color="primary" className={classes.submitButton} onClick={(event) => this.handleClick(event, 'save')}>Salvar</Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Paper>
                                </div>
                            </Grid>
                        </Grid>
                    </main>
                </div>
            </div>
        );
    }
}
EstacaoEdit.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
    return state;
}

const connectedEstacaoEditPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(withStyles(styles)(EstacaoEdit)));
export { connectedEstacaoEditPage as EstacaoEdit };