const initialState = {
    estacao: [],
    id: '',
    usuarioId: 0,
    fonteId: 0,
    nome: '',
    codigo: '',
    senha: '',
    aprovada: '',
    token: '',
    cidadeId: 0,
    apelido: '',
    link: '',
    latitude: '',
    longitude: '',
    cidade: [],
    fonte: []
};

export function estacao(state = initialState, action) {
    switch (action.type) {
        case 'FETECHED_ALL_ESTACAO':
            return {
                ...state,
                estacao: action.estacao
            };
        case 'ESTACAO_DETAIL':
            return {
                ...state,
                id: action.id,
                usuarioId: action.usuarioId,
                fonteId: action.fonteId,
                nome: action.nome,
                codigo: action.codigo,
                senha: action.senha,
                aprovada: action.aprovada,
                token: action.token,
                cidadeId: action.cidadeId,
                cidade: action.cidade,
                fonte: action.fonte,
                apelido: action.apelido,
                link: action.link,
                latitude: action.latitude,
                longitude: action.longitude
            };
        case "ESTACAO_CLEAR":
            return initialState;
        case "ESTACAO_UPDATED":
            return state;
        case "HANDLE_ON_CHANGE_ESTACAO":
            return {
                ...state,
                [action.props]: action.value
            };
        default:
            return state;
    }
}
