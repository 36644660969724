export const slugMask = (value) => {
    if (value !== null && value !== undefined) {
        return value
            .normalize('NFD')
            .replace(/(^-+|-+$)/, '') // Remove hífens extras do final ou do inicio da string
            .replace(/[\u0300-\u036f]/g, '') // Remove acentos
            .replace(/([^\w]+|\s+)/g, '-') // Substitui espaço e outros caracteres por hífen
            .replace(/\-\-+/g, '-') // Substitui multiplos hífens por um único hífen
            .toLowerCase() //tudo em letra minuscula
    }
}

export const cpfCnpjMask = (value) => {
    if (value !== null && value !== undefined) {
        if (value.length === 14 && value.includes('-')) {
            return cpfMask(value)
        }
        if (value.length >= 14) {
            return cnpjMask(value)
        } else {
            return cpfMask(value)
        }
    }
}

export const cpfMask = (value) => {
    if (value !== null && value !== undefined) {
        return value
            .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
            .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
    }
}
export const cnpjMask = (value) => {
    if (value !== null && value !== undefined) {
        return value
            .replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1/$2')
            .replace(/(\d{4})(\d)/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1')
    }
}

export const phoneMask = (value) => {
    if (value !== null && value !== undefined) {
        return value
            .replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '($1) $2')
            .replace(/(\d{4})(\d)/, '$1-$2')
            .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
            .replace(/(-\d{4})\d+?$/, '$1')
    }
}

export const cepMask = (value) => {
    if (value !== null && value !== undefined) {
        return value
            .replace(/\D/g, '')
            .replace(/(\d{5})(\d)/, '$1-$2')
            .replace(/(-\d{3})\d+?$/, '$1')
    }
}

export const pisMask = (value) => {
    if (value !== null && value !== undefined) {
        return value
            .replace(/\D/g, '')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{5})(\d)/, '$1.$2')
            .replace(/(\d{5}\.)(\d{2})(\d)/, '$1$2-$3')
            .replace(/(-\d{1})\d+?$/, '$1')
    }
}

export const dataMask = (value) => {
    if (value !== null && value !== undefined) {
        return value
            .replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '$1/$2')
            .replace(/(\d{2})(\d)/, '$1/$2')
            .replace(/(\d{2})(\d{2})$/, '$1$2')
    }
}
