import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Tooltip, Toolbar, Grid, Button, Link } from '@material-ui/core';
import { history } from '../_helpers';
import MailIcon from '@material-ui/icons/Mail';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import ReactGA from 'react-ga';
import classNames from 'classnames';

const drawerWidth = 240;
const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    appFrame: {
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        width: '100%',
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
    },
    'appBar-left': {
        marginLeft: drawerWidth,
    },
    'appBar-right': {
        marginRight: drawerWidth,
    },
    drawerPaper: {
        position: 'relative',
        width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
    },
    loginButton: {
        color: '#ffffff'
    }

});
class PermanentDrawer extends React.Component {

    render() {
        const { classes } = this.props;

        return (
            <AppBar
             position="absolute"
             className={classNames(classes.appBar, classes[`appBar-left`])}
            >
                <Toolbar>
                    <Grid container spacing={24}>
                        <Grid item xs={3} container alignContent="flex-start">
                            <Toolbar>
                                <Link href="/">
                                    <img src={require('../imgs/logo branco png.png')} width="77px" height="43px" alt="logo" className={classes.logo} />
                                </Link>                            
                            </Toolbar>
                        </Grid>
                        <Grid item xs={9} container justify="flex-end">
                            <Tooltip title="Como instalar uma estação meteorológica particular" aria-label="info">
                                <IconButton className={classes.loginButton} onClick={() => history.push("/orientacoes")}>
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="WhatsApp para +55 (51) 9998-6571" aria-label="info">
                                <IconButton className={classes.loginButton} onClick={() => window.open('https://wa.me/555199986571', '_blank')}>
                                    <WhatsAppIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="E-mail para contato@estacoesdobrasil.com.br" aria-label="info">
                                <IconButton className={classes.loginButton} onClick={() => window.open("mailto:contato@estacoesdobrasil.com.br")}>
                                    <MailIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Toolbar>
           </AppBar>
        );
    }
}
PermanentDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(PermanentDrawer);