const initialState = {
    parametro: [],
    id: '',
    chave: '',
    valor: '',
    descricao: ''
};

export function parametro(state = initialState, action) {
    switch (action.type) {
        case 'FETECHED_ALL_PARAMETRO':
            return {
                ...state,
                parametro: action.parametro
            };
        case 'PARAMETRO_DETAIL':
            return {
                ...state,
                id: action.id,
                chave: action.chave,
                valor: action.valor,
                descricao: action.descricao
            };
        case "PARAMETRO_CLEAR":
            return initialState;
        case "PARAMETRO_UPDATED":
            return state;
        case "HANDLE_ON_CHANGE_PARAMETRO":
            return {
                ...state,
                [action.props]: action.value
            };
        default:
            return state
    }
}
