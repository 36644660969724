import { crudService } from '../_services/';
import { history } from '../_helpers';

export const cidadeAction = {
    getCidade,
    getCidadeSelect,
    clear
};
function getCidade() {
    return dispatch => {
        let apiEndpoint = 'cidades';
        crudService.get(apiEndpoint)
            .then((response) => {
                dispatch(changeCidadesList(response.data));
            }).catch((err) => {
                console.log(err);
            })
    };
}
function getCidadeSelect() {
    return dispatch => {
        let apiEndpoint = 'cidades/select';
        crudService.get(apiEndpoint)
            .then((response) => {
                //response.data.unshift({ id: 0, nome: 'Selecione ...', estadoId: 0, ibge: 0, latLon: null, estado: null });
                dispatch(changeCidadesList(response.data));
            }).catch((err) => {
                console.log(err);
            })
    };
}
export function changeCidadesList(cidade) {
    return {
        type: "FETECHED_ALL_CIDADE",
        cidade: cidade
    }
}
export function clear() {
    return {
        type: "CIDADE_CLEAR",
    }
}
function onChangeProps(props, event) {
    return dispatch => {
        dispatch(handleOnChangeProps(props, event.target.value));
    }
}
export function handleOnChangeProps(props, value) {
    return {
        type: "HANDLE_ON_CHANGE_CIDADE",
        props: props,
        value: value
    }
}