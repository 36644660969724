import { connect } from 'react-redux';
import React, { Component } from 'react';
import AppBar from '../_components/appbarvisualization';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Paper, Grid, Link } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import ResponsiveDrawer from '../_components/responsiveDrawer';
import throttle from 'lodash.throttle';

const drawerWidth = 210;
const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    appFrame: {
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        //display: 'flex',
        width: '100%',
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
    },
    'appBar-left': {
        marginLeft: drawerWidth,
    },
    'appBar-right': {
        marginRight: drawerWidth,
    },
    drawerPaper: {
        position: 'relative',
        width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
    },
    paper: {
        position: 'absolute',
        width: theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
    },
    gridItem: {
        padding: 5,
        alignSelf: 'flex-end'
    },
    newButton: {
        color: '#ffffff'
    },
    drawerPaper: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
        background: '#ffffff'
    },
    imageContent: {
        maxWidth: '100%'
    }
});
class Orientacao extends Component {

    state = {
        isMobile: window.innerWidth < 468
    }

    componentDidMount() {
        window.addEventListener('resize', this.throttledHandleWindowResize);
    }
    throttledHandleWindowResize = () => {
        return throttle(() => {
            this.setState({ isMobile: window.innerWidth < 468 });
            this.forceUpdate();
        }, 200);
    }
    componentWillUnmount() {
        window.addEventListener('resize', this.throttledHandleWindowResize);
    }

    render() {

        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <div className={classes.appFrame} style={{ display: this.state.isMobile ? 'block' : 'flex' }}>
                    <ResponsiveDrawer />
                    <main className={classes.content}>
                        <div className={classes.toolbar} />
                        <Paper className={classes.drawerPaper}>
                            <Grid container spacing={24}>
                                <Grid item lg={12} sm={12} xs={12}>
                                    <Typography align="justify" variant="h5">{'COMO INSTALAR UMA ESTAÇÃO METEOROLÓGICA PARTICULAR'}</Typography>
                                    <br />
                                    <Typography align="justify" variant="h6">{'O QUE É UMA ESTAÇÃO METEOROLÓGICA PARTICULAR?'}</Typography>
                                    <br />
                                    <Typography align="justify">Uma estação meteorológica particular é um conjunto de instrumentos de medição das variáveis meteorológicas que você pode instalar na sua casa ou empresa. O número de instrumentos de medição (Sensores) pode variar, mas o mais comum são os sensores de temperatura, umidade relativa do ar, pressão atmosférica, precipitação (chuva) e velocidade e direção do vento.</Typography>
                                    <br />
                                    <Typography align="justify" variant="h6">{'MODELOS RECOMENDADOS'}</Typography>
                                    <br />
                                    <Typography align="justify">
                                        Existem diversos modelos de estações meteorológicas particulares no mercado, porém os mais indicados são os da Marca Davis Instruments, sendo os Modelos Vantage Vue e Vantage Pro 2 e suas variações. Outro modelo indicado é da marca Ambient Weather, especificamente o modelo WS-2902 e semelhantes.
                                        Estes são os modelos mais indicados, principalmente da marca Davis, em virtude da confiabilidade e qualidade dos dados fornecidos, além da robustez do equipamento, que é sem dúvidas, a melhor opção disponível no mercado.
                                        O modelo de entrada da Davis é a Vantage Vue, que possui sensor de temperatura e umidade relativa do ar, pressão atmosférica, pluviômetro e de velocidade e direção do vento.
                                        Já a Davis Vantage Pro 2 6152, possui estes mesmos sensores da Vantage Vue, mas também existem outros modelos que possuem sensore de radiação solar e UV, além de ser possível adicionar mais sensores, como de umidade do solo, temperatura do solo, umidade das folhas, sensores extra de temperatura, além de diversas outras opções, além de abrigo aspirado para o sensor de temperatura e umidade.
                                    </Typography>
                                    <br />
                                    <Grid container spacing={24}>
                                        <Grid item lg={6} sm={12} xs={12}>
                                            <img src={require('../imgs/Davis Vantage Pro 2.jpg')} className={classes.imageContent} width="200px" height="200px" alt="Davis Vantage Pro 2" />
                                            <Typography align="center">{'Davis Vantage Pro 2'}</Typography>
                                        </Grid>
                                        <Grid item lg={6} sm={12} xs={12}>
                                            <img src={require('../imgs/Davis Vantage Vue.jpg')} className={classes.imageContent} width="212px" height="200px" alt="Davis Vantage Vue" />
                                            <Typography align="center">{'Davis Vantage Vue'}</Typography>
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <Typography align="justify">
                                        Tanto a Davis Vantage Vue quanto a Pro 2, necessitam de um datalogger para armazenar e transmitir os dados para a internet. Dentre as opções, existe o Weatherlink Live, da própria Davis Instruments, que inclusive dispensa o uso da tela (Console) e pode ser ligado diretamente ao roteador via cabo ou wifi. Outra alternativa é o Wifilogger, que é de outro fabricante, mas foi desenvolvido especificamente para as estações da Davis. Ele necessita de tela e a conexão com o roteador é via Wifi. Também existe outras maneiras de transmitir os dados para a internet, como através de um computador, Meteohub ou Meteobridge, mas o mais prático e comum é a utilização do Weatherlink Live ou Wifilogger.
                                    </Typography>
                                    <br />
                                    <Grid container spacing={24}>
                                        <Grid item lg={6} sm={12} xs={12}>
                                            <img src={require('../imgs/Weatherlink Live.jpg')} className={classes.imageContent} width="289px" height="200px" alt="Weatherlink Live" />
                                            <Typography align="center">{'Weatherlink Live'}</Typography>
                                        </Grid>
                                        <Grid item lg={6} sm={12} xs={12}>
                                            <img src={require('../imgs/Wifilogger.jpg')} className={classes.imageContent} width="194px" height="200px" alt="Wifilogger" />
                                            <Typography align="center">{'Wifilogger'}</Typography>
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <Typography align="justify">
                                        Já as estações meteorológicas da Ambient Weather, especificamente o modelo WS-2902, também apresenta dados de qualidade, porém é um equipamento menos robusto e está um patamar abaixo das estações Davis, não deixando de ser uma boa alternativa para quem deseja ter uma estação meteorológica particular. É um modelo que já acompanha datalogger e está pronto para transmitir os dados para a internet.
                                    </Typography>
                                    <br />
                                    <Grid container spacing={24}>
                                        <Grid item lg={12} sm={12} xs={12}>
                                            <img src={require('../imgs/WS-2902.jpg')} className={classes.imageContent} width="552px" alt="WS-2902" />
                                            <Typography align="center">{'WS-2902'}</Typography>
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <Typography align="justify">
                                        Outros modelos de estações meteorológicas também podem ser utilizados, porém deve-se observar se os sensores são de qualidade e o abrigo do sensor de temperatura, se não causa superaquecimento. Outro detalhe e o mais importante, é o que trataremos em seguida, que é o local de instalação da estação meteorológica. Um local inadequado, pode fazer o melhor equipamento reportar dados incorretos.
                                    </Typography>
                                    <br />
                                    <Typography align="center">
                                        Alguma dúvida sobre qual modelo escolher? Entre em contato e tire suas dúvidas:
                                    </Typography>
                                    <Typography align="center">
                                        contato@estacoesdobrasil.com.br ou (51) 9-99986571
                                    </Typography>
                                    <br />
                                    <Typography align="justify" variant="h6">{'INSTALAÇÃO DA ESTAÇÃO METEOROLÓGICA'}</Typography>
                                    <br />
                                    <br />
                                    <Typography align="justify">
                                        O local de instalação da estação meteorológica é a parte mais importante da instalação. Se você instalar a estação muito próximo de uma árvore, o pluviômetro pode não registrar corretamente a chuva, ou se instalar muito próximo de uma construção, os dados de temperatura podem ser afetados. Locais pouco arejados, com muitas árvores ou prédios ao redor, por exemplo, influenciam tanto nos dados de vento, quanto de temperatura. Portanto, achar um local adequado em uma área urbana ou suburbana, pode ser um desafio, mas existem alternativas para ter dados de qualidade nestas situações. O local em que a estação meteorológica é instalada influencia mais na qualidade dos dados do que o próprio equipamento.
                                    </Typography>
                                    <br />
                                    <Grid container spacing={24}>
                                        <Grid item lg={6} sm={12} xs={12}>
                                            <img src={require('../imgs/Wrong1.png')} className={classes.imageContent} width="200px" height="200px" alt="Exemplo de instalação errada 1" />
                                        </Grid>
                                        <Grid item lg={6} sm={12} xs={12}>
                                            <img src={require('../imgs/Wrong2.png')} className={classes.imageContent} width="212px" height="200px" alt="Exemplo de instalação errada 2" />
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <Typography align="justify">
                                        Existe o cenário ideal para a instalação de uma estação meteorológica, que basicamente pode ser descrito da seguinte forma:
                                    </Typography>
                                    <br />
                                    <Typography align="justify" variant="h6">{'TEMPERATURA E UMIDADE'}</Typography>
                                    <br />
                                    <Typography align="justify">
                                        <ul>
                                            <li>- Local nivelado e que representa boa parte da área, preferencialmente sobre gramado.</li>
                                            <li>- Altura do sensor entre 1,25 m e 2,0 m.</li>
                                            <li>- Sensores devem ficar a 2 vezes a distância da altura do objeto mais próximo (Árvores, construções).</li>
                                            <li>- Manter distância de fontes de calor, construções, muros, chaminés, ar condicionado, etc.</li>
                                        </ul>
                                    </Typography>
                                    <br />
                                    <Typography align="justify" variant="h6">{'ANEMÔMETRO'}</Typography>
                                    <br />
                                    <Typography align="justify">
                                        <ul>
                                            <li>- Preferencialmente a 10 m de altura do solo.</li>
                                            <li>- Manter distância de 10 vezes a altura do objeto mais próximo (Árvore ou construção).</li>
                                        </ul>
                                    </Typography>
                                    <br />
                                    <Typography align="justify" variant="h6">{'PLUVIÔMETRO'}</Typography>
                                    <br />
                                    <Typography align="justify">
                                        <ul>
                                            <li>- Altura do sensor entre 1,25 e 2,0 m.</li>
                                            <li>- Manter distância horizontal de 4 vezes a altura do objeto mais próximo (Árvores, construções).</li>
                                        </ul>
                                    </Typography>
                                    <br />
                                    <Typography align="justify">
                                        Este é o cenário ideal para estações meteorológicas que possuem sensores separados, porém para áreas urbanas é um tanto quanto difícil e os modelos indicados aqui, possuem todos os sensores integrados em um único equipamento (Exceto a Davis Vantage Pro 2, que pode ter o anemômetro instalado separadamente dos demais sensores). Sendo assim, segue abaixo alguns exemplos de instalações recomendadas para os equipamentos que foram relacionados anteriormente:
                                    </Typography>
                                    <br />
                                    <Typography align="justify" variant="h6">{'DAVIS VANTAGE VUE'}</Typography>
                                    <br />
                                    <Grid container spacing={24}>
                                        <Grid item lg={12} sm={12} xs={12}>
                                            <img src={require('../imgs/maxresdefault.jpg')} className={classes.imageContent} width="552px" alt="Exemplo ideal Davis Vantage Vue" />
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <Typography align="justify">
                                        Cenário ideal, quando se tem espaço e sem árvores ou construções próximas. Os sensores podem ficar a uma altura de 1,25 a 2,0 m, o que facilita muito a manutenção, principalmente do pluviômetro, que pode entupir com sujeira de folhas ou fezes de pássaros. O anemômetro não está na altura ideal, mas é uma alternativa aceitável.
                                    </Typography>
                                    <br />
                                    <Grid container spacing={24}>
                                        <Grid item lg={12} sm={12} xs={12}>
                                            <img src={require('../imgs/6250-6357_1400x500_VV_BradHickman.jpg')} className={classes.imageContent} width="552px" alt="Exemplo área urbana Davis Vantage Vue" />
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <Typography align="justify">
                                        Aqui temos um exemplo de instalação da Davis Vantage Vue em uma área urbana, com casas, muros e árvores próximas. Ela foi instalada o mais alto possível, para receber vento de todas as direções e também a uma altura de pelo menos 3 m do telhado, para não sofrer com o calor proveniente do telhado.
                                    </Typography>
                                    <br />
                                    <Typography align="justify" variant="h6">{'DAVIS VANTAGE PRO 2'}</Typography>
                                    <br />
                                    <Grid container spacing={24}>
                                        <Grid item lg={12} sm={12} xs={12}>
                                            <img src={require('../imgs/Cenario ideal DAVIS VANTAGE PRO 2.jpg')} className={classes.imageContent} width="552px" alt="Exemplo ideal Davis Vantage Pro 2" />
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <Typography align="justify">
                                        Cenário ideal, como da Davis Vantage Vue acima, quando se tem espaço e sem árvores ou construções próximas. Os sensores podem ficar a uma altura de 1,25 a 2,0 m, o que facilita muito a manutenção, principalmente do pluviômetro, que pode entupir com sujeira de folhas ou fezes de pássaros. O anemômetro não está na altura ideal, mas é uma alternativa aceitável.
                                    </Typography>
                                    <br />
                                    <Grid container spacing={24}>
                                        <Grid item lg={12} sm={12} xs={12}>
                                            <img src={require('../imgs/Cenario area urbana DAVIS VANTAGE PRO 2.jpg')} className={classes.imageContent} width="552px" alt="Exemplo área urbana Davis Vantage Pro 2" />
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <Typography align="justify">
                                        Cenário urbano, com construções e casas, da mesma forma que com a Davis Vantage Pro 2, a estação foi instalada em um mastro a pelo menos 3 metros de altura do telhado, recebendo assim, vento de todos os quadrantes e evitando superaquecimento proveniente do telhado.
                                    </Typography>
                                    <br />
                                    <Grid container spacing={24}>
                                        <Grid item lg={12} sm={12} xs={12}>
                                            <img src={require('../imgs/021-640x385-640x385.jpg')} className={classes.imageContent} width="552px" alt="Exemplo alturas ideais Davis Vantage Pro 2" />
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <Typography align="justify">
                                        Com a Davis Vantage Pro 2, quando existirem condições de instalar o sensor de temperatura/umidade e pluviômetro na altura padrão, existe a possibilidade de instalar o anemômetro também na altura padrão, que é de 10 m. Isto é possível pois ele conta com um cabo de 12 m, então pode-se ter todos os sensores nas suas alturas ideias.
                                    </Typography>
                                    <br />
                                    <Typography align="justify" variant="h6">{'AMBIENT WEATHER WS-2902'}</Typography>
                                    <br />
                                    <Grid container spacing={24}>
                                        <Grid item lg={12} sm={12} xs={12}>
                                            <img src={require('../imgs/WhatsApp Image 2020-05-28 at 17.15.11.jpeg')} className={classes.imageContent} width="552px" alt="Exemplo ideal WS-2902" />
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <Typography align="justify">
                                        Em áreas abertas, sem construções e árvores próximas, o conjunto de sensores está na altura ideal, entre 1,25 e 2,0 m. Fácil manutenção, mas com o anemômetro fora da altura padrão de 10m, o que não é um problema neste caso.
                                    </Typography>
                                    <br />
                                    <Grid container spacing={24}>
                                        <Grid item lg={12} sm={12} xs={12}>
                                            <img src={require('../imgs/94138758_3604637852885376_3822128711386267648_o.jpg')} className={classes.imageContent} height="552px" alt="Exemplo área urbana WS-2902" />
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <Typography align="justify">
                                        WS-2902 instalada em área urbana, com construções ou árvores próximas. Foi colocado no topo de um mastro, recebendo assim vento de todas as direções e longe de telhados e construções que podem gerar aquecimento.
                                    </Typography>
                                    <br />
                                    <Typography align="center">
                                        Quer ajuda ou alguma dica na instalação da sua estação meteorológica? Entre em contato:
                                    </Typography>
                                    <br />
                                    <Typography align="center">
                                        contato@estacoesdobrasil.com.br ou (51) 9-99986571.
                                    </Typography>
                                    <br />
                                    <br />
                                    <Typography align="justify">
                                        Fontes:
                                        <ul>
                                            <li><Link target="_blank" href="https://www.wunderground.com/pws/installation-guide">https://www.wunderground.com/pws/installation-guide</Link></li>
                                            <li><Link target="_blank" href="https://www.weather.gov/media/epz/mesonet/CWOP-Siting.pdf">https://www.weather.gov/media/epz/mesonet/CWOP-Siting.pdf</Link></li>
                                        </ul>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </main>
                </div>
            </div>
        );
    }
}
Orientacao.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
    return {
        state
    };
}
const connectedOrientacaoPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(withStyles(styles)(Orientacao)));
export { connectedOrientacaoPage as Orientacao };