const initialState = {
    pais: [],
    id: '',
    nome: '',
    nomePt: '',
    iso2: '',
    iso3: '',
    bacen: ''
};

export function pais(state = initialState, action) {
    switch (action.type) {
        case 'FETECHED_ALL_PAIS':
            return {
                ...state,
                pais: action.pais
            };
        case 'PAIS_DETAIL':
            return {
                ...state,
                id: action.id,
                nome: action.nome,
                nomePt: action.nomePt,
                iso2: action.iso2,
                iso3: action.iso3,
                bacen: action.bacen
            };
        case "PAIS_CLEAR":
            return initialState;
        case "PAIS_UPDATED":
            return state;
        case "HANDLE_ON_CHANGE_PAIS":
            return {
                ...state,
                [action.props]: action.value
            };
        default:
            return state
    }
}
