import { connect } from 'react-redux';
import { estacaoAction } from '../_actions';
import React, { Component } from 'react';
import AppBar from '../_components/appbar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Nav from '../_components/nav';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Grid, Button, TableContainer } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import { withRouter } from 'react-router-dom';
import { history } from '../_helpers';
import ResponsiveDrawer from '../_components/responsiveDrawer';
import throttle from 'lodash.throttle';

const drawerWidth = 210;
const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    appFrame: {
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        //display: 'flex',
        width: '100%',
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
    },
    'appBar-left': {
        marginLeft: drawerWidth,
    },
    'appBar-right': {
        marginRight: drawerWidth,
    },
    drawerPaper: {
        position: 'relative',
        width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
    },
    paper: {
        position: 'absolute',
        width: theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
    },
    gridItem: {
        padding: 5,
        alignSelf: 'flex-end'
    },
    newButton: {
        color: '#ffffff'
    }
});
class MinhaEstacao extends Component {

    state = {
        isMobile: window.innerWidth < 468
    }
    componentDidMount() {
        const { dispatch } = this.props;
        var usuarioId = this.props.state.authentication.user.id;
        dispatch(estacaoAction.clear());
        dispatch(estacaoAction.getEstacaoUsuarioById(usuarioId));        
        window.addEventListener('resize', this.throttledHandleWindowResize);
    }
    throttledHandleWindowResize = () => {
        return throttle(() => {
            this.setState({ isMobile: window.innerWidth < 468 });
            this.forceUpdate();
        }, 200);
    }
    componentWillUnmount() {
        window.addEventListener('resize', this.throttledHandleWindowResize);
    }
    handleClick = (event, id) => {
        const { dispatch } = this.props;
        dispatch(estacaoAction.deleteEstacaoById(id));
    };
    render() {

        const { classes } = this.props;
        const { estacao } = this.props.state.estacao;

        return (
            <div className={classes.root}>
                <div className={classes.appFrame} style={{ display: this.state.isMobile ? 'block' : 'flex' }}>
                    <ResponsiveDrawer />
                    <main className={classes.content}>
                        <div className={classes.toolbar} />
                        <Grid container spacing={24}>
                            <Grid item xs={3} container justify="flex-start">
                                <Typography variant="h5"><b>{'Estações'}</b></Typography>
                            </Grid>
                            <Grid item xs={6}>
                            </Grid>
                            <Grid item xs={3} container justify="flex-end">
                                <Button variant="contained" color="primary" className={classes.newButton} component='a' onClick={() => history.push(`/estacao`)}>NOVA</Button>
                            </Grid>
                        </Grid>
                        <br />
                        <Grid container spacing={24}>
                            <Grid item xs={3} container justify="flex-start" className={classes.gridItem}>
                                <Paper className={classes.root}>
                                    <Typography><b>{'Total'}</b></Typography>
                                    <Typography><b>{estacao.length}</b></Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={3} container justify="flex-start" className={classes.gridItem}>
                                <Paper className={classes.root}>
                                    <Typography><b>{'Apro.'}</b></Typography>
                                    <Typography><b>{estacao.filter(item => (item.aprovada != null && item.aprovada == true)).length}</b></Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={3} container justify="flex-start" className={classes.gridItem}>
                                <Paper className={classes.root}>
                                    <Typography><b>{'Aguard.'}</b></Typography>
                                    <Typography><b>{estacao.filter(item => (item.aprovada == null)).length}</b></Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={3} container justify="flex-start" className={classes.gridItem}>
                                <Paper className={classes.root}>
                                    <Typography><b>{'Repro.'}</b></Typography>
                                    <Typography><b>{estacao.filter(item => (item.aprovada != null && item.aprovada == false)).length}</b></Typography>
                                </Paper>
                            </Grid>
                        </Grid>
                        <br />
                        <Grid container spacing={24}>
                            <TableContainer component={Paper}>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Código</TableCell>
                                            <TableCell>Usuário</TableCell>
                                            <TableCell>Nome</TableCell>
                                            <TableCell>Localização</TableCell>
                                            <TableCell>Situação</TableCell>
                                            <TableCell>Ações</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            estacao.map(n => {

                                                var location = '';
                                                if (n.cidade != null && n.cidade.estado != null & n.cidade.estado.pais != null) {
                                                    location = n.cidade.nome + ', ' + n.cidade.estado.uf + ', ' + n.cidade.estado.pais.nomePt;
                                                } else if (n.cidade.estado != null & n.cidade.estado.pais != null) {
                                                    location = n.cidade.estado.uf + ', ' + n.cidade.estado.pais.nomePt;
                                                } else if (n.cidade.estado.pais != null) {
                                                    location = n.cidade.estado.pais.nomePt;
                                                }

                                                var situacao = '';
                                                if (n.aprovada != null && n.aprovada == true) {
                                                    situacao = 'Aprovada';
                                                } else if (n.aprovada != null && n.aprovada == false) {
                                                    situacao = 'Reprovada';
                                                } else {
                                                    situacao = 'Aguardando aprovação'
                                                }
                                                
                                                return (
                                                    <TableRow key={n.id}>
                                                        <TableCell component="th" scope="row">
                                                            {n.codigo}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {n.usuario ? n.usuario.nome : ''}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {n.apelido ? n.apelido : n.nome}
                                                        </TableCell>
                                                        <TableCell>{location}</TableCell>
                                                        <TableCell>{situacao}</TableCell>
                                                        <TableCell>
                                                            <IconButton className={classes.button} aria-label="Edit" component='a' onClick={() => history.push(`/estacao/${n.id}`)}>
                                                                <EditIcon />
                                                            </IconButton>
                                                            <IconButton lassName={classes.button} aria-label="Delete" onClick={(event) => this.handleClick(event, n.id)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </main>
                </div>
            </div>
        );
    }
}
MinhaEstacao.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
    return {
        state
    };
}
const connectedMinhaEstacaoPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(withStyles(styles)(MinhaEstacao)));
export { connectedMinhaEstacaoPage as MinhaEstacao };