const initialState = {
    visualization: []
};

export function visualization(state = initialState, action) {
    switch (action.type) {
        case 'FETECHED_ALL_VISUALIZATION':
            return {
                ...state,
                visualization: action.visualization
            };
        default:
            return state
    }
}
