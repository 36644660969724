const initialState = {
    usuario: [],
    id: '',
    email: '',
    nome: '',
    senha: '',
    papel: '',
    telefone: '',
    log: []
};

export function usuario(state = initialState, action) {
    switch (action.type) {
        case 'FETECHED_ALL_USUARIO':
            return {
                ...state,
                usuario: action.usuario
            };
        case 'USUARIO_DETAIL':
            return {
                ...state,
                id: action.id,
                email: action.email,
                nome: action.nome,
                senha: action.senha,
                papel: action.papel,
                telefone: action.telefone,
                log: action.log
            };
        case "USUARIO_CLEAR":
            return initialState;
        case "USUARIO_UPDATED":
            return state;
        case "HANDLE_ON_CHANGE_USUARIO":
            return {
                ...state,
                [action.props]: action.value
            };
        default:
            return state
    }
}
