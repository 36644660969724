const initialState = {
    fonte: [],
    id: '',
    descricao: '',
    url: ''
};

export function fonte(state = initialState, action) {
    switch (action.type) {
        case 'FETECHED_ALL_FONTE':
            return {
                ...state,
                fonte: action.fonte
            };
        case 'FONTE_DETAIL':
            return {
                ...state,
                id: action.id,
                descricao: action.descricao,
                url: action.url
            };
        case "FONTE_CLEAR":
            return initialState;
        case "FONTE_UPDATED":
            return state;
        case "HANDLE_ON_CHANGE_FONTE":
            return {
                ...state,
                [action.props]: action.value
            };
        default:
            return state
    }
}
