import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { Paper, Grid, Container, Typography, IconButton } from '@material-ui/core';
import { history } from '../_helpers';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { withStyles } from '@material-ui/core/styles';
import { usuarioAction } from '../_actions';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { phoneMask } from '../utils';

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
        width: theme.spacing(9),
        height: theme.spacing(9),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        color: '#ffffff'
    },
    backButton: {
        marginTop: theme.spacing(1),
    }
});

class Cadastro extends Component {

    componentDidMount() {

        const { dispatch } = this.props;
        dispatch(usuarioAction.clear());

        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.props.usuario.senha) {
                return false;
            }
            return true;
        });

        ValidatorForm.addValidationRule('isValidPhone', (value) => {
            if (value != null) {
                if (value.length > 0 && value.length < 14) {
                    return false
                }
                return true
            }
            return true
        })

    }

    state = {
        submitted: false,
        repeatPassword: '',
    }

    handleChange = prop => event => {
        const { dispatch } = this.props;
        dispatch(usuarioAction.onChangeProps(prop, event));
    };

    handleChangeRepeatPassword = prop => event => {
        this.setState({ [prop]: event.target.value });
    };

    handleSubmit = (event) => {
        this.setState({ submitted: true }, () => {
            setTimeout(() => this.setState({ submitted: false }), 2500);
        });
        const { dispatch } = this.props;
        let payload = {
            id: 0,
            email: this.props.usuario.email,
            nome: this.props.usuario.nome,
            senha: this.props.usuario.senha,
            telefone: this.props.usuario.telefone.split(' ').join('').replace(/[()-]/g, ''),
            papel: 'usuario'
        }
        console.log('payload', payload);
        dispatch(usuarioAction.createUsuario(payload));
    }

    render() {
        const { submitted } = this.state;
        const { classes } = this.props;

        return (
            <Container component={Paper} maxWidth="sm">
                <CssBaseline />
                <div className={classes.paper}>
                    <Grid container justify="flex-start">
                        <IconButton className={classes.backButton} onClick={() => history.goBack()} aria-label="Voltar" color="primary">
                            <KeyboardBackspaceIcon />
                        </IconButton>
                    </Grid>
                    <Typography component="h2" variant="h6">
                        Venha fazer parte do Estações do Brasil! Informe seus dados abaixo para se cadastrar.
                    </Typography>
                    <ValidatorForm className={classes.form}
                        ref="form"
                        onSubmit={this.handleSubmit}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <TextValidator
                                    autoComplete="nome"
                                    name="nome"
                                    variant="outlined"
                                    fullWidth
                                    value={this.props.usuario.nome}
                                    onChange={this.handleChange('nome')}
                                    id="nome"
                                    label="Nome completo *"
                                    validators={['required']}
                                    errorMessages={['Campo obrigatório!']}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextValidator
                                    variant="outlined"
                                    fullWidth
                                    type="email"
                                    id="email"
                                    label="Endereço de e-mail *"
                                    name="email"
                                    value={this.props.usuario.email}
                                    onChange={this.handleChange('email')}
                                    validators={['required', 'isEmail']}
                                    errorMessages={['Você precisa informar o e-mail', 'E-mail inválido']}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextValidator
                                    variant="outlined"
                                    fullWidth
                                    type="telefone"
                                    id="telefone"
                                    label="Telefone"
                                    name="telefone"
                                    value={phoneMask(this.props.usuario.telefone)}
                                    onChange={this.handleChange('telefone')}
                                    validators={['isValidPhone']}
                                    errorMessages={['Telefone inválido']}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <TextValidator
                                    variant="outlined"
                                    fullWidth
                                    name="senha"
                                    label="Senha *"
                                    type="password"
                                    value={this.props.usuario.senha}
                                    onChange={this.handleChange('senha')}
                                    id="senha"
                                    validators={['required']}
                                    errorMessages={['Opss! Você esqueceu da senha.']}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <TextValidator
                                    variant="outlined"
                                    fullWidth
                                    name="repeatPassword"
                                    label="Repita a senha *"
                                    type="password"
                                    value={this.state.repeatPassword}
                                    onChange={this.handleChangeRepeatPassword('repeatPassword')}
                                    id="repeatPassword"
                                    validators={['isPasswordMatch', 'required']}
                                    errorMessages={['As senhas não são iguais!','Opss! Você esqueceu da senha.']}
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={submitted}
                            className={classes.submit}
                        >
                            {
                                (submitted && 'Enviando informações...')
                                || (!submitted && 'Cadastrar')
                            }
                        </Button>
                    </ValidatorForm>
                </div>
            </Container>
        );
    }
}
Cadastro.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return state;
}
const connectedCadastroPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(withStyles(styles)(Cadastro)));
export { connectedCadastroPage as Cadastro };