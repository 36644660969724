const initialState = {
    pagamento: [],
    id: '',
    usuarioId: '',
    dataPagamento: '',
    dataInicioValidade: '',
    dataFimValidade: '',
    valor: 0,
    formaPagamento: '',
    status: '',
    transacao: '',
    usuario: null
};

export function pagamento(state = initialState, action) {
    switch (action.type) {
        case 'FETECHED_ALL_PAGAMENTO':
            return {
                ...state,
                pagamento: action.pagamento
            };
        case 'PAGAMENTO_DETAIL':
            return {
                ...state,
                id: action.id,
                usuarioId: action.usuarioId,
                dataPagamento: action.dataPagamento,
                dataInicioValidade: action.dataInicioValidade,
                dataFimValidade: action.dataFimValidade,
                valor: action.valor,
                formaPagamento: action.formaPagamento,
                status: action.status,
                transacao: action.transacao,
                usuario: action.usuario
            };
        case "PAGAMENTO_CLEAR":
            return initialState;
        case "PAGAMENTO_UPDATED":
            return state;
        case "HANDLE_ON_CHANGE_PAGAMENTO":
            return {
                ...state,
                [action.props]: action.value
            };
        default:
            return state;
    }
}
