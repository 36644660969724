import { connect, useDispatch } from "react-redux";
import { visualizationAction, estadoAction, cidadeAction, fonteAction, pagamentoAction, estacaoAction } from '../_actions';
import React, { useState, useEffect } from 'react';
import AppBar from '../_components/appbarvisualization';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import './visualization.component.css'
import '../imgs'
import imgTermometro_0_10 from '../imgs/termometro/termometro-0-10.png';
import imgTermometro_10_20 from '../imgs/termometro/termometro-10-20.png';
import imgTermometro_20_30 from '../imgs/termometro/termometro-20-30.png';
import { Select, InputLabel, MenuItem, Input, FormControl, Button, Grid, Paper, ButtonGroup, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography, Tooltip, TableContainer } from '@material-ui/core';
import Swal from 'sweetalert2';
import { history } from '../_helpers';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import MapIcon from '@material-ui/icons/Map';
import { MarkerInfoWindow } from './';
import { Skeleton } from '@material-ui/lab';
import Image from '../imgs/estacoes.PNG'; // Import using relative path

const drawerWidth = 240;
const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1.2
    },
    paperContainer: {
        backgroundImage: `url(${Image})`,
        height: '100vh',
        width: '100%'
    },
    paperOpacity: {
        height: '95vh',
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        fontWeight: 'bold',
        color: '#ffffff',
        borderRadius: 5
    },
    appFrame: {
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        width: '100%',
    },
    mapFrame: {
        width: '100%',
        height: '100vh',
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex'
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
    },
    'appBar-left': {
        marginLeft: drawerWidth,
    },
    'appBar-right': {
        marginRight: drawerWidth,
    },
    drawerPaper: {
        position: 'relative',
        width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
    },
    paper: {
        position: 'absolute',
        width: theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4
    },
    gridItem: {
        padding: 5,
        alignSelf: 'flex-end'
    },
    clearButton: {
        color: '#ffffff'
    }
});


const Visualization = (props) => {

    const dispatch = useDispatch();

    const [selectedEstado, setSelectedEstado] = useState([0]);
    const [selectedCidade, setSelectedCidade] = useState([0]);
    const [selectedFonte, setSelectedFonte] = useState(0);
    const [selectedSituacao, setSelectedSituacao] = useState(0);
    const [selectedColumn, setSelectedColumn] = useState('');
    const [selectedOrder, setSelectedOrder] = useState('');
    const [, forceUpdate] = React.useState(0);
    const [view, setView] = useState(1);
    const [isMobile, setIsMobile] = useState(false);
    const [isTabActive, setIsTabActive] = useState(true);

    window.onfocus = function () {
        setIsTabActive(true);
    };

    window.onblur = function () {
        setIsTabActive(false);
    };

    useEffect(() => {

        //showAlertSorteio();

        //dispatch(visualizationAction.getVisualization());
        refreshVisualization();

        try {
            setInterval(async () => {
                //dispatch(visualizationAction.getVisualization());
                refreshVisualization();
            }, 60000);
        } catch (e) {
            console.log(e);
        }

        setIsMobile(window.innerWidth < 468);

        dispatch(estadoAction.getEstadoSelect());
        dispatch(cidadeAction.getCidadeSelect());
        dispatch(fonteAction.getFonteSelect());
        forceUpdate(n => !n);

        if (props.state && props.state.authentication && props.state.authentication.user && props.state.authentication.user.id > 0) {
            var usuarioId = props.state.authentication.user.id;
            dispatch(pagamentoAction.getPagamentoAtivoUsuarioById(usuarioId));
        } else {
            dispatch(pagamentoAction.clear());
        }

    }, [])

    const refreshVisualization = () => {

        if (isTabActive) {
            dispatch(visualizationAction.getVisualization());
        }

    }

    useEffect(() => {

        if (props.state && props.state.authentication && props.state.authentication.user && props.state.authentication.user.id > 0) {
            var usuarioId = props.state.authentication.user.id;
            dispatch(pagamentoAction.getPagamentoAtivoUsuarioById(usuarioId));
        } else {
            dispatch(pagamentoAction.clear());
        }

    }, [props.state.authentication])

    //useEffect(() => {

    //    //if (props.state && props.state.pagamento && props.state.pagamento.id > 0) {

    //    for (var i = 1; i < 99999; i++)
    //        window.clearInterval(i);

    //    dispatch(visualizationAction.getVisualization());

    //    try {
    //        setInterval(async () => {
    //            dispatch(visualizationAction.getVisualization());
    //        }, 40000);
    //    } catch (e) {
    //        console.log(e);
    //    }

    //    //}

    //}, [props.state.pagamento])

    useEffect(() => {

    }, [props.state.pagamento, props.state.visualization.visualization.estacoes, props.state.visualization.visualization.estacoesFavoritas])

    //useEffect(() => {
    //    console.log('selectedColumnm', selectedColumn)
    //    console.log('selectedOrder', selectedOrder)
    //}, [selectedColumn, selectedOrder])

    const handleChangeSelectedEstado = value => {
        if (props.state.pagamento && props.state.pagamento.id > 0) {
            setSelectedEstado(value.filter(item => item !== 0));
            renderOptionsCidade();
        } else {
            showAlertPagamento();
        }
        dispatch(visualizationAction.getVisualization());
        forceUpdate(n => !n);
    }

    const handleChangeSelectedCidade = value => {
        if (props.state.pagamento && props.state.pagamento.id > 0) {
            setSelectedCidade(value.filter(item => item !== 0));
        } else {
            showAlertPagamento();
        }
        dispatch(visualizationAction.getVisualization());
        forceUpdate(n => !n);
    }

    const handleChangeSelectedFonte = value => {
        if (props.state.pagamento && props.state.pagamento.id > 0) {
            setSelectedFonte(value);
        } else {
            showAlertPagamento();
        }
        dispatch(visualizationAction.getVisualization());
        forceUpdate(n => !n);
    }

    const handleChangeSelectedSituacao = value => {
        if (props.state.pagamento && props.state.pagamento.id > 0) {
            setSelectedSituacao(value);
        } else {
            showAlertPagamento();
        }
        dispatch(visualizationAction.getVisualization());
        forceUpdate(n => !n);
    }

    const handleChangeSelectedColumn = value => {
        if (value != null && value != '') {
            if (props.state.pagamento && props.state.pagamento.id > 0) {
                setSelectedColumn(value);
            } else {
                showAlertPagamento();
            }
            dispatch(visualizationAction.getVisualization());
            forceUpdate(n => !n);
        } else {
            setSelectedColumn('');
        }
    }

    const handleChangeSelectedOrder = value => {
        if (value != null && value != '') {
            if (props.state.pagamento && props.state.pagamento.id > 0) {
                setSelectedOrder(value);
            } else {
                showAlertPagamento();
            }
            dispatch(visualizationAction.getVisualization());
            forceUpdate(n => !n);
        } else {
            setSelectedOrder('');
        }
    }

    const showAlertPagamento = () => {

        Swal.fire({
            title: 'Recurso disponível apenas para assinantes.',
            html: "<p>Faça seu login ou então cadastre-se e efetue a assinatura para prosseguir com os filtros.</br></br>Para novos assinante há a possibilidade <b>testar por 7 dias grátis</b>.</p>",
            html: "<p>Efetue a assinatura para prosseguir com a visualização. Por apenas R$ 8,25 por mês (R$ 99,00/ano), você tem acesso a filtros e estações de todo o Brasil.</br></br>Para novos visitantes há a possibilidade <b>testar por 7 dias gratuitamente.</b></p>",
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonColor: '#3c5186',
            cancelButtonColor: '#3c5186',
            cancelButtonText: 'Assinar'
            //imageUrl: 'https://i.imgur.com/lkt1TLh.jpg'
        }).then((result) => {
            if (result.value) {
                history.push('/login');
            }
            else if (result.dismiss == 'cancel') {
                history.push('/pagamentos');
            }
        })

    }

    const showAlertSorteio = () => {

        Swal.fire({
            title: 'ASSINE E CONCORRA',
            html: "<p>Faça seu login ou cadastre-se, efetue a assinatura, além de adquirir acesso completo à plataforma, você ainda concorre a uma <b>Estação Meteorológica Davis Vantage Vue com Weatherlink Live</b>. Sorteio dia 31/05/2021.</p>",
            showCloseButton: true,
            confirmButtonColor: '#3c5186',
            confirmButtonText: 'ASSINAR AGORA',
            imageUrl: 'https://i.imgur.com/lkt1TLh.jpg'
        }).then((result) => {
            if (result.value) {
                history.push('/pagamentos');
            }
        })

    }    

    const handleClickLimpar = event => {

        setSelectedEstado([0]);
        setSelectedCidade([0]);
        setSelectedFonte(0);
        setSelectedSituacao(0);

        if (props.state.pagamento && props.state.pagamento.id > 0) {
            setSelectedColumn('temperatura');
            setSelectedOrder('asc');
        } else {
            setSelectedColumn('');
            setSelectedOrder('');
        }

        dispatch(visualizationAction.getVisualization());
        forceUpdate(n => !n);

    }

    const handleClickFav = event => {
        if (props.state.pagamento && props.state.pagamento.id > 0) {
            dispatch(estacaoAction.favEstacao(event));
        } else {
            showAlertPagamento();
        }
    }

    const handleClickUnfav = event => {
        if (props.state.pagamento && props.state.pagamento.id > 0) {
            dispatch(estacaoAction.unfavEstacao(event));
        } else {
            showAlertPagamento();
        }
    }

    function isFav(id) {
        var ret = false;
        if (estacoesFavoritas != null && estacoesFavoritas.length > 0) {
            estacoesFavoritas.forEach(function (fav) {
                if (fav.id == id) {
                    ret = true;
                }
            });
        }
        return ret;
    }

    const renderOptionsEstado = () => {
        return props.state.estado.estado.map((p, i) => {
            if (p.paisId === 1) {
                return (
                    <MenuItem
                        value={p.id}
                        key={i} name={p.nome}>{p.nome}
                    </MenuItem>
                );
            }
        });
    };

    const renderOptionsCidade = () => {
        return props.state.cidade.cidade.map((p, i) => {
            if (selectedEstado.includes(p.estadoId) || p.id === 0) {
                return (
                    <MenuItem
                        value={p.id}
                        key={i} name={p.nome}>{p.nome}
                    </MenuItem>
                );
            }
        });
    };

    const renderOptionsFonte = () => {
        return props.state.fonte.fonte.map((p, i) => {
            return (
                <MenuItem
                    value={p.id}
                    key={i} name={p.descricao}>{p.descricao}
                </MenuItem>
            );
        });
    };

    const renderOptionsSituacao = () => {
        var columnData = [
            { value: 1, name: 'Ativas' },
            { value: 2, name: 'Inativas' }
        ];
        return columnData.map((p, i) => {
            return (
                <MenuItem
                    value={p.value}
                    key={i} name={p.name}>{p.name}
                </MenuItem>
            );
        });
    };

    const renderOptionsColumn = () => {
        var columnData = [
            { value: null, name: 'Selecione ...' },
            { value: 'temperatura', name: 'Temperatura' },
            { value: 'temperaturaMaxima', name: 'Temperatura Máx.' },
            { value: 'temperaturaMinima', name: 'Temperatura Mín.' },
            { value: 'amplitudeTermica', name: 'Amplitude Térmica' },
            { value: 'ura', name: 'U.R.A.' },
            { value: 'po', name: 'P.O.' },
            { value: 'chuva', name: 'Chuva' },
            { value: 'totalChuvaDia', name: 'Total Chuva Dia' },
            { value: 'ventoVelocidade', name: 'Veloc. do Vento' },
            { value: 'rajadaVentoMaxima', name: 'Rajada Máx.' }
        ];
        return columnData.map((p, i) => {
            return (
                <MenuItem
                    value={p.value}
                    key={i} name={p.name}>{p.name}
                </MenuItem>
            );
        });
    };

    const renderOptionsOrder = () => {
        var orderData = [
            { value: null, name: 'Selecione ...' },
            { value: 'asc', name: 'Ascendente' },
            { value: 'desc', name: 'Descendente' },
        ];
        return orderData.map((p, i) => {
            return (
                <MenuItem
                    value={p.value}
                    key={i} name={p.name}>{p.name}
                </MenuItem>
            );
        });
    };

    const { classes } = props;
    const { estacoesFavoritas } = props.state.visualization.visualization;
    const { estacoes } = props.state.visualization.visualization;

    const getNested = (obj, selectedColumn) => selectedColumn.split('.').reduce((a, prop) => a[prop], obj);
    const myCompare = (a, b) => {
        const col = getNested(a, selectedColumn);
        const colAtiva = !getNested(a, 'ativa');
        var noValue = false;

        if (selectedColumn !== 'nome' && (isNaN(col) || col == null)) {
            noValue = true;
        }

        return typeof selectedColumn === 'nome'
            ? col.localeCompare(getNested(b, selectedColumn))
            : noValue || colAtiva - !getNested(b, 'ativa') || (selectedOrder === 'asc' ? col - getNested(b, selectedColumn) : getNested(b, selectedColumn) - col);
    };

    if (estacoesFavoritas != null) {
        if (selectedColumn != null && selectedColumn != '') {
            estacoesFavoritas.sort(myCompare);
        } else {
            //shuffle(estacoesFavoritas);
        }
    }
    if (estacoes != null) {
        if (selectedColumn != null && selectedColumn != '') {
            estacoes.sort(myCompare);
        } else {
            //shuffle(estacoes);
        }
        
    }

    //function shuffle(array) {
    //    let currentIndex = array.length, randomIndex;
    //
    //    // While there remain elements to shuffle.
    //    while (currentIndex != 0) {
    //
    //        // Pick a remaining element.
    //        randomIndex = Math.floor(Math.random() * currentIndex);
    //        currentIndex--;
    //
    //        // And swap it with the current element.
    //        [array[currentIndex], array[randomIndex]] = [
    //            array[randomIndex], array[currentIndex]];
    //    }
    //
    //    return array;
    //}

    return (
        <div >
            <div className={classes.appFrame} style={{ display: (isMobile && view != 3) ? 'block' : 'flex' }}>
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <Grid container spacing={24}>
                        <AppBar />
                        <Grid item xs={6}>
                        </Grid>
                        <Grid item xs={3} container justify="flex-end">
                        </Grid>
                    </Grid>
                    <Grid container spacing={24}>
                        <Paper className={classes.root} disabled>
                            <div>
                                <Grid container spacing={24}>
                                    <Grid item xs={6} sm={3} md={3} lg={3} className={classes.gridItem}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="estado">Estado</InputLabel>
                                            <Select
                                                name="estado"
                                                value={selectedEstado}
                                                onChange={event => handleChangeSelectedEstado(event.target.value)}
                                                input={<Input id="estado" />}
                                                multiple
                                            >
                                                <MenuItem value={0} disabled>
                                                    Selecione ...
                                                </MenuItem>
                                                {renderOptionsEstado()}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6} sm={3} md={3} lg={3} className={classes.gridItem}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="cidade">Cidade</InputLabel>
                                            <Select
                                                name="cidade"
                                                value={selectedCidade}
                                                onChange={event => handleChangeSelectedCidade(event.target.value)}
                                                input={<Input id="cidade" />}
                                                multiple
                                            >
                                                <MenuItem value={0} disabled>
                                                    Selecione ...
                                                </MenuItem>
                                                {renderOptionsCidade()}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={6} sm={3} md={3} lg={3} className={classes.gridItem}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="fonte">Fonte</InputLabel>
                                            <Select
                                                name="fonte"
                                                value={selectedFonte}
                                                onChange={event => handleChangeSelectedFonte(event.target.value)}
                                                input={<Input id="fonte" />}
                                            >
                                                <MenuItem value={0} disabled>
                                                    Selecione ...
                                                </MenuItem>
                                                {renderOptionsFonte()}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={6} sm={3} md={3} lg={3} className={classes.gridItem}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="situacao">Situação</InputLabel>
                                            <Select
                                                name="situacao"
                                                value={selectedSituacao}
                                                onChange={event => handleChangeSelectedSituacao(event.target.value)}
                                                input={<Input id="situacao" />}
                                            >
                                                <MenuItem value={0} disabled>
                                                    Selecione ...
                                                </MenuItem>
                                                {renderOptionsSituacao()}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={6} sm={3} md={3} lg={3} className={classes.gridItem}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="column">Ordenar por</InputLabel>
                                            <Select
                                                name="column"
                                                value={selectedColumn || ''}
                                                onChange={event => handleChangeSelectedColumn(event.target.value)}
                                                input={<Input id="column" />}
                                            >
                                                {renderOptionsColumn()}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={6} sm={3} md={3} lg={3} className={classes.gridItem}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="order">Asc./Desc.</InputLabel>
                                            <Select
                                                name="order"
                                                value={selectedOrder || ''}
                                                onChange={event => handleChangeSelectedOrder(event.target.value)}
                                                input={<Input id="order" />}
                                            >
                                                {renderOptionsOrder()}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={6} sm={3} md={3} lg={3} className={classes.gridItem}>

                                    </Grid>
                                    <Grid xs={6} sm={3} md={3} lg={3} className={classes.gridItem}>
                                        <Grid container spacing={24}>
                                            <Grid item xs={12} container justify="flex-end">
                                                <Button variant="contained" color="primary" className={classes.clearButton} onClick={(event) => handleClickLimpar(event)}>LIMPAR</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                    </Grid>

                    <br />
                    <Grid container spacing={24} justify="flex-end">
                        <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                            <IconButton aria-label={`Card`} onClick={() => setView(1)} >
                                <Tooltip title="Visualizar Cards" aria-label="card">
                                    <ViewModuleIcon />
                                </Tooltip>
                            </IconButton>
                            <IconButton aria-label={`Lista`} onClick={() => setView(2)} >
                                <Tooltip title="Visualizar Lista" aria-label="list">
                                    <ViewHeadlineIcon />
                                </Tooltip>
                            </IconButton>
                            <IconButton aria-label={`Mapa`} onClick={() => setView(3)} >
                                <Tooltip title="Visualizar Mapa" aria-label="map">
                                    <MapIcon />
                                </Tooltip>
                            </IconButton>
                        </ButtonGroup>
                    </Grid>
                    <br />

                    {(view == 1 ?

                        (estacoesFavoritas != null && estacoesFavoritas.length > 0 ?

                            <Grid container spacing={24}>
                                <Paper className={classes.root}>
                                    <Typography align="left" variant="subtitle2" style={{ fontWeight: 600, marginBottom: '10px' }}>Estações Favoritas</Typography>
                                    <ul id="estacoes" >
                                        {

                                            estacoesFavoritas.map(n => {

                                                if (!selectedCidade.includes(0)) {
                                                    if (selectedCidade.length > 0 && !(selectedCidade.includes(n.cidadeId))) {
                                                        return;
                                                    }
                                                }

                                                if (!selectedEstado.includes(0)) {
                                                    if (selectedEstado.length > 0 && !(selectedEstado.includes(n.estadoId))) {
                                                        return;
                                                    }
                                                }

                                                if (selectedFonte !== '' && selectedFonte !== 0 && n.fonteId !== selectedFonte) {
                                                    return;
                                                }

                                                if (selectedSituacao !== '' && selectedSituacao !== 0 && n.ativa.toString() !== (selectedSituacao == 1 ? 'true' : 'false')) {
                                                    return;
                                                }

                                                var tempScale = ['#170D1E', '#2D1B34', '#43284D', '#543261', '#3E3061',
                                                    '#31326F', '#394586', '#4543A0', '#3E6391', '#487FA6', '#488CA7',
                                                    '#4593A0', '#4899A7', '#48A6A7', '#48A79A', '#49A781', '#49A774',
                                                    '#49A767', '#49A75A', '#49A744', '#51A749', '#7AA947', '#92A849',
                                                    '#9FA849', '#A8A449', '#A8974A', '#B38240', '#C56841', '#D05835',
                                                    '#DF1E1E', '#CB1C12', '#C10000', '#AA0000', '#8C0000', '#800000',
                                                    '#640000', '#420000', '#170000'];

                                                const tempValue = (selectedColumn == 'temperatura' ? (parseFloat(n.temperatura) + 12) : (selectedColumn == 'temperaturaMinima' ? (parseFloat(n.temperaturaMinima) + 12) : (selectedColumn == 'temperaturaMaxima' ? (parseFloat(n.temperaturaMaxima) + 12) : (parseFloat(n.temperatura) + 12))));
                                                var scaleNumber = Math.round(tempValue / 1.5);
                                                var bgcolor = "#999";
                                                var seconds = (new Date() - new Date(n.dataAtualizacao).getTime()) / 1000;

                                                if (n.ativa) {
                                                    if (scaleNumber > (tempScale.length - 1)) {
                                                        scaleNumber = (tempScale.length - 1);
                                                    }
                                                    if (scaleNumber < 0) {
                                                        scaleNumber = 0;
                                                    }
                                                    bgcolor = tempScale[scaleNumber];
                                                }

                                                var img = imgTermometro_0_10;
                                                if (n.temperatura <= 10) {
                                                    img = imgTermometro_0_10;
                                                } else if (n.temperatura > 10 && n.temperatura <= 20) {
                                                    img = imgTermometro_10_20;
                                                } else if (n.temperatura > 20) {
                                                    img = imgTermometro_20_30;
                                                }

                                                n.temperatura = parseFloat(n.temperatura).toFixed(1);
                                                n.temperaturaMinima = parseFloat(n.temperaturaMinima).toFixed(1);
                                                n.temperaturaMaxima = parseFloat(n.temperaturaMaxima).toFixed(1);
                                                n.po = parseFloat(n.po).toFixed(1);
                                                n.ventoVelocidade = parseFloat(n.ventoVelocidade).toFixed(1);

                                                if (n.rajadaVentoMaxima) {
                                                    n.rajadaVentoMaxima = parseFloat(n.rajadaVentoMaxima).toFixed(1);
                                                } else {
                                                    n.rajadaVentoMaxima = parseFloat(0).toFixed(1);
                                                }

                                                if (n.totalChuvaDia) {
                                                    n.totalChuvaDia = parseFloat(n.totalChuvaDia).toFixed(2);
                                                } else {
                                                    n.totalChuvaDia = parseFloat(0).toFixed(2);
                                                }

                                                var styles = { transform: `rotate(${90 + n.ventoGrau}deg)`, transformOrigin: '50% 50%' };

                                                var location = '';
                                                if (n.uf != null && n.cidadeNome != null) {
                                                    location = n.cidadeNome + ', ' + n.uf;
                                                } else if (n.uf != null) {
                                                    location = n.uf;
                                                } else if (n.cidadeNome != null) {
                                                    location = n.cidadeNome;
                                                }

                                                var nome = (n.apelido ? n.apelido : n.nome);
                                                if (nome.length > 25) {
                                                    nome = nome.substring(0, 25);
                                                }

                                                function displayTime(seconds) {
                                                    const format = val => `${Math.floor(val)}`.slice(-2)
                                                    const hours = seconds / 3600
                                                    const minutes = (seconds % 3600) / 60

                                                    if (format(hours) != '0' && format(minutes) != '0' && format(seconds) != '0') {
                                                        return format(hours) + 'h ' + format(minutes) + 'm ' + format(seconds % 60) + 's';
                                                    } else if (format(minutes) != '0' && format(seconds) != '0') {
                                                        return format(minutes) + 'm ' + format(seconds % 60) + 's';
                                                    } else if (format(seconds) != '0') {
                                                        return format(seconds % 60) + 's';
                                                    } else {
                                                        return '-';
                                                    }

                                                }

                                                var link = '';
                                                if (n.fonteId === 3) {
                                                    link = 'https://tempo.inmet.gov.br/GraficosDiarios/' + n.codigo;
                                                } else if (n.fonteId === 2) {
                                                    link = 'https://www.wunderground.com/dashboard/pws/' + n.codigo;
                                                } else if (n.link != null) {
                                                    link = n.link;
                                                }

                                                return (
                                                    <li rel="modal" id={n.codigo} className="estacao" style={{ backgroundColor: bgcolor }}>
                                                        <div className="stationhead">
                                                            <div className="stationname">
                                                                {link !== ''
                                                                    ?
                                                                    <div className="nome"><a target="_blank" href={link}>{nome}</a></div>
                                                                    :
                                                                    <div className="nome">{nome}</div>
                                                                }
                                                                <div className="local" align="left"><span align="left">{location}</span></div>
                                                            </div>
                                                            <div className="temperature"> <span className="temperatura">
                                                                <img title="Temperatura Atual" src={img} />
                                                                <span className="value" title={n.temperatura}>{n.temperatura}ºC</span> </span>
                                                            </div>
                                                        </div>
                                                        <div className="data">
                                                            <div className="temperatura_max"><p align="left"><span className="label" align="left">Temp. Máx.</span></p><p align="left"><span className="value" align="left" title={n.temperaturaMaxima}>{(isNaN(n.temperaturaMaxima) ? '' : n.temperaturaMaxima + 'ºC')}</span></p></div>
                                                            <div className="temperatura_min"><p align="left"><span className="label" align="left">Temp. Mín.</span></p><p align="left"><span className="value" align="left" title={n.temperaturaMinima}>{(isNaN(n.temperaturaMinima) ? '' : n.temperaturaMinima + 'ºC')}</span></p></div>
                                                            <div className="URA"><p align="left"><span className="label" align="left">U.R.A.</span></p><p align="left"><span className="value" align="left">{((isNaN(n.ura) || n.ura == null) ? '' : n.ura + '%')}</span></p></div>
                                                            <div className="PO"><p align="left"><span className="label" align="left">P.O.</span></p><p align="left"><span className="value" align="left">{(isNaN(n.po) ? '' : n.po + 'ºC')}</span></p></div>
                                                            <div className="chuva"><p align="left"><span className="label" align="left">Chuva</span></p><p align="left"><span className="value" align="left">{((isNaN(n.chuva) || n.chuva == null) ? '' : n.chuva + 'mm/h')}</span></p></div>
                                                            <div className="total_chuva_dia"><p align="left"><span className="label" align="left">Tot. Chuva Dia</span></p><p align="left"><span className="value" align="left">{n.totalChuvaDia}mm</span></p></div>
                                                            <div className="pressao"><p align="left"><span className="label" align="left">Pressão</span></p><p align="left"><span className="value" align="left">{((isNaN(n.pressao) || n.pressao == null) ? '' : n.pressao + 'hPa')}</span></p></div>
                                                            <div className="rajadaVentoMaxima"><p align="left"><span className="label" align="left">Rajada Máx.</span></p><p align="left"><span className="value" align="left">{((isNaN(n.rajadaVentoMaxima) || n.rajadaVentoMaxima == null) ? '' : n.rajadaVentoMaxima + 'Km/h')}</span></p></div>
                                                            <div className="atualizacao"><p align="left"><span className="label" align="left">Atualizado há</span></p><p align="left"><span className="value" align="left">{displayTime(seconds)}</span></p></div>
                                                        </div>
                                                        <div className="winddata">
                                                            <div className="compass">
                                                                <div className="arrow" style={styles} />
                                                                <div className="VV"> <span className="value">{(isNaN(n.ventoVelocidade) || n.ventoVelocidade == null ? '' : n.ventoVelocidade + ' Km/h')}</span> </div>
                                                            </div>
                                                        </div>
                                                        <div className="favdata">
                                                            {(n.estacaoFavorita != null && n.estacaoFavorita.length > 0 && isFav(n.id) ?
                                                                <Tooltip title="Desfavoritar estação" aria-label="fav">
                                                                    <StarIcon onClick={() => handleClickUnfav(n.id)} />
                                                                </Tooltip>
                                                                :
                                                                <Tooltip title="Favoritar estação" aria-label="unfav">
                                                                    <StarBorderIcon onClick={() => handleClickFav(n.id)} />
                                                                </Tooltip>
                                                            )}
                                                        </div>
                                                    </li>
                                                );

                                            })

                                        }

                                    </ul>
                                </Paper>
                            </Grid>

                            :

                            null
                        )

                        :

                        null
                    )}

                    {(view == 1 ?

                        (estacoes != null && estacoes.length > 0 ?

                            <Grid container spacing={24}>
                                <Paper className={classes.root}>
                                    <Typography align="left" variant="subtitle2" style={{ fontWeight: 600, marginBottom: '10px' }}>Todas Estações</Typography>
                                    <ul id="estacoes" >
                                        {

                                            estacoes.map(n => {

                                                if (!selectedCidade.includes(0)) {
                                                    if (selectedCidade.length > 0 && !(selectedCidade.includes(n.cidadeId))) {
                                                        return;
                                                    }
                                                }

                                                if (!selectedEstado.includes(0)) {
                                                    if (selectedEstado.length > 0 && !(selectedEstado.includes(n.estadoId))) {
                                                        return;
                                                    }
                                                }

                                                if (selectedFonte !== '' && selectedFonte !== 0 && n.fonteId !== selectedFonte) {
                                                    return;
                                                }

                                                if (selectedSituacao !== '' && selectedSituacao !== 0 && n.ativa.toString() !== (selectedSituacao == 1 ? 'true' : 'false')) {
                                                    return;
                                                }

                                                var tempScale = ['#170D1E', '#2D1B34', '#43284D', '#543261', '#3E3061',
                                                    '#31326F', '#394586', '#4543A0', '#3E6391', '#487FA6', '#488CA7',
                                                    '#4593A0', '#4899A7', '#48A6A7', '#48A79A', '#49A781', '#49A774',
                                                    '#49A767', '#49A75A', '#49A744', '#51A749', '#7AA947', '#92A849',
                                                    '#9FA849', '#A8A449', '#A8974A', '#B38240', '#C56841', '#D05835',
                                                    '#DF1E1E', '#CB1C12', '#C10000', '#AA0000', '#8C0000', '#800000',
                                                    '#640000', '#420000', '#170000'];

                                                const tempValue = (selectedColumn == 'temperatura' ? (parseFloat(n.temperatura) + 12) : (selectedColumn == 'temperaturaMinima' ? (parseFloat(n.temperaturaMinima) + 12) : (selectedColumn == 'temperaturaMaxima' ? (parseFloat(n.temperaturaMaxima) + 12) : (parseFloat(n.temperatura) + 12))));
                                                var scaleNumber = Math.round(tempValue / 1.5);
                                                var bgcolor = "#999";
                                                var seconds = (new Date() - new Date(n.dataAtualizacao).getTime()) / 1000;

                                                if (n.ativa) {
                                                    if (scaleNumber > (tempScale.length - 1)) {
                                                        scaleNumber = (tempScale.length - 1);
                                                    }
                                                    if (scaleNumber < 0) {
                                                        scaleNumber = 0;
                                                    }
                                                    bgcolor = tempScale[scaleNumber];
                                                }

                                                var img = imgTermometro_0_10;
                                                if (n.temperatura <= 10) {
                                                    img = imgTermometro_0_10;
                                                } else if (n.temperatura > 10 && n.temperatura <= 20) {
                                                    img = imgTermometro_10_20;
                                                } else if (n.temperatura > 20) {
                                                    img = imgTermometro_20_30;
                                                }

                                                n.temperatura = parseFloat(n.temperatura).toFixed(1);
                                                n.temperaturaMinima = parseFloat(n.temperaturaMinima).toFixed(1);
                                                n.temperaturaMaxima = parseFloat(n.temperaturaMaxima).toFixed(1);
                                                n.po = parseFloat(n.po).toFixed(1);
                                                n.ventoVelocidade = parseFloat(n.ventoVelocidade).toFixed(1);

                                                if (n.rajadaVentoMaxima) {
                                                    n.rajadaVentoMaxima = parseFloat(n.rajadaVentoMaxima).toFixed(1);
                                                } else {
                                                    n.rajadaVentoMaxima = parseFloat(0).toFixed(1);
                                                }

                                                if (n.totalChuvaDia) {
                                                    n.totalChuvaDia = parseFloat(n.totalChuvaDia).toFixed(2);
                                                } else {
                                                    n.totalChuvaDia = parseFloat(0).toFixed(2);
                                                }

                                                var styles = { transform: `rotate(${90 + n.ventoGrau}deg)`, transformOrigin: '50% 50%' };

                                                var location = '';
                                                if (n.uf != null && n.cidadeNome != null) {
                                                    location = n.cidadeNome + ', ' + n.uf;
                                                } else if (n.uf != null) {
                                                    location = n.uf;
                                                } else if (n.cidadeNome != null) {
                                                    location = n.cidadeNome;
                                                }

                                                var nome = (n.apelido ? n.apelido : n.nome);
                                                if (nome.length > 25) {
                                                    nome = nome.substring(0, 25);
                                                }

                                                function displayTime(seconds) {
                                                    const format = val => `${Math.floor(val)}`.slice(-2)
                                                    const hours = seconds / 3600
                                                    const minutes = (seconds % 3600) / 60

                                                    if (format(hours) != '0' && format(minutes) != '0' && format(seconds) != '0') {
                                                        return format(hours) + 'h ' + format(minutes) + 'm ' + format(seconds % 60) + 's';
                                                    } else if (format(minutes) != '0' && format(seconds) != '0') {
                                                        return format(minutes) + 'm ' + format(seconds % 60) + 's';
                                                    } else if (format(seconds) != '0') {
                                                        return format(seconds % 60) + 's';
                                                    } else {
                                                        return '-';
                                                    }

                                                }

                                                var link = '';
                                                if (n.fonteId === 3) {
                                                    link = 'https://tempo.inmet.gov.br/GraficosDiarios/' + n.codigo;
                                                } else if (n.fonteId === 2) {
                                                    link = 'https://www.wunderground.com/dashboard/pws/' + n.codigo;
                                                } else if (n.link != null) {
                                                    link = n.link;
                                                }

                                                return (
                                                    <li rel="modal" id={n.codigo} className="estacao" style={{ backgroundColor: bgcolor }}>
                                                        <div className="stationhead">
                                                            <div className="stationname">
                                                                {link !== ''
                                                                    ?
                                                                    <div className="nome"><a target="_blank" href={link}>{nome}</a></div>
                                                                    :
                                                                    <div className="nome">{nome}</div>
                                                                }
                                                                <div className="local" align="left"><span align="left">{location}</span></div>
                                                            </div>
                                                            <div className="temperature"> <span className="temperatura">
                                                                <img title="Temperatura Atual" src={img} />
                                                                <span className="value" title={n.temperatura}>{n.temperatura}ºC</span> </span>
                                                            </div>
                                                        </div>
                                                        <div className="data">
                                                            <div className="temperatura_max"><p align="left"><span className="label" align="left">Temp. Máx.</span></p><p align="left"><span className="value" align="left" title={n.temperaturaMaxima}>{(isNaN(n.temperaturaMaxima) ? '' : n.temperaturaMaxima + 'ºC')}</span></p></div>
                                                            <div className="temperatura_min"><p align="left"><span className="label" align="left">Temp. Mín.</span></p><p align="left"><span className="value" align="left" title={n.temperaturaMinima}>{(isNaN(n.temperaturaMinima) ? '' : n.temperaturaMinima + 'ºC')}</span></p></div>
                                                            <div className="URA"><p align="left"><span className="label" align="left">U.R.A.</span></p><p align="left"><span className="value" align="left">{((isNaN(n.ura) || n.ura == null) ? '' : n.ura + '%')}</span></p></div>
                                                            <div className="PO"><p align="left"><span className="label" align="left">P.O.</span></p><p align="left"><span className="value" align="left">{(isNaN(n.po) ? '' : n.po + 'ºC')}</span></p></div>
                                                            <div className="chuva"><p align="left"><span className="label" align="left">Chuva</span></p><p align="left"><span className="value" align="left">{((isNaN(n.chuva) || n.chuva == null) ? '' : n.chuva + 'mm/h')}</span></p></div>
                                                            <div className="total_chuva_dia"><p align="left"><span className="label" align="left">Tot. Chuva Dia</span></p><p align="left"><span className="value" align="left">{n.totalChuvaDia}mm</span></p></div>
                                                            <div className="pressao"><p align="left"><span className="label" align="left">Pressão</span></p><p align="left"><span className="value" align="left">{((isNaN(n.pressao) || n.pressao == null) ? '' : n.pressao + 'hPa')}</span></p></div>
                                                            <div className="rajadaVentoMaxima"><p align="left"><span className="label" align="left">Rajada Máx.</span></p><p align="left"><span className="value" align="left">{((isNaN(n.rajadaVentoMaxima) || n.rajadaVentoMaxima == null) ? '' : n.rajadaVentoMaxima + 'Km/h')}</span></p></div>
                                                            <div className="atualizacao"><p align="left"><span className="label" align="left">Atualizado há</span></p><p align="left"><span className="value" align="left">{displayTime(seconds)}</span></p></div>
                                                        </div>
                                                        <div className="winddata">
                                                            <div className="compass">
                                                                <div className="arrow" style={styles} />
                                                                <div className="VV"> <span className="value">{(isNaN(n.ventoVelocidade) || n.ventoVelocidade == null ? '' : n.ventoVelocidade + ' Km/h')}</span> </div>
                                                            </div>
                                                        </div>
                                                        <div className="favdata">
                                                            {(n.estacaoFavorita != null && n.estacaoFavorita.length > 0 && isFav(n.id) ?
                                                                <Tooltip title="Desfavoritar estação" aria-label="fav">
                                                                    <StarIcon onClick={() => handleClickUnfav(n.id)} />
                                                                </Tooltip>
                                                                :
                                                                <Tooltip title="Favoritar estação" aria-label="unfav">
                                                                    <StarBorderIcon onClick={() => handleClickFav(n.id)} />
                                                                </Tooltip>
                                                            )}
                                                        </div>
                                                    </li>
                                                );

                                            })

                                        }
                                    </ul>
                                </Paper>
                            </Grid>

                            :

                            <Grid container spacing={24}>
                                <Paper className={classes.root}>
                                    <ul id="estacoes" >
                                        <li rel="modal" id={0} className="estacao" style={{ background: 'transparent' }}>
                                            <Skeleton variant="rect" width={314} height={238} />
                                        </li>
                                        <li rel="modal" id={1} className="estacao" style={{ background: 'transparent' }}>
                                            <Skeleton variant="rect" width={314} height={238} />
                                        </li>
                                        <li rel="modal" id={2} className="estacao" style={{ background: 'transparent' }}>
                                            <Skeleton variant="rect" width={314} height={238} />
                                        </li>
                                        <li rel="modal" id={3} className="estacao" style={{ background: 'transparent' }}>
                                            <Skeleton variant="rect" width={314} height={238} />
                                        </li>
                                        <li rel="modal" id={4} className="estacao" style={{ background: 'transparent' }}>
                                            <Skeleton variant="rect" width={314} height={238} />
                                        </li>
                                        <li rel="modal" id={5} className="estacao" style={{ background: 'transparent' }}>
                                            <Skeleton variant="rect" width={314} height={238} />
                                        </li>
                                        <li rel="modal" id={6} className="estacao" style={{ background: 'transparent' }}>
                                            <Skeleton variant="rect" width={314} height={238} />
                                        </li>
                                        <li rel="modal" id={7} className="estacao" style={{ background: 'transparent' }}>
                                            <Skeleton variant="rect" width={314} height={238} />
                                        </li>
                                        <li rel="modal" id={4} className="estacao" style={{ background: 'transparent' }}>
                                            <Skeleton variant="rect" width={314} height={238} />
                                        </li>
                                        <li rel="modal" id={5} className="estacao" style={{ background: 'transparent' }}>
                                            <Skeleton variant="rect" width={314} height={238} />
                                        </li>
                                        <li rel="modal" id={6} className="estacao" style={{ background: 'transparent' }}>
                                            <Skeleton variant="rect" width={314} height={238} />
                                        </li>
                                        <li rel="modal" id={7} className="estacao" style={{ background: 'transparent' }}>
                                            <Skeleton variant="rect" width={314} height={238} />
                                        </li>
                                    </ul>
                                </Paper>
                            </Grid>

                        )

                        : (view == 2 ?

                            <Grid container spacing={24}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Nome</TableCell>
                                                <TableCell>Localização</TableCell>
                                                <TableCell>Temp. (Cº)</TableCell>
                                                <TableCell>Temp. Mín. (Cº)</TableCell>
                                                <TableCell>Temp. Máx. (Cº)</TableCell>
                                                <TableCell>U.R.A</TableCell>
                                                <TableCell>P.O (Cº)</TableCell>
                                                <TableCell>Chuva (mm/h)</TableCell>
                                                <TableCell>Tot. Chuva Dia (mm)</TableCell>
                                                <TableCell>Pressão (hPa)</TableCell>
                                                <TableCell>Veloc. Vento (km/h)</TableCell>
                                                <TableCell>Rajada Máxima (km/h)</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                estacoes.map(n => {

                                                    if (!selectedCidade.includes(0)) {
                                                        if (selectedCidade.length > 0 && !(selectedCidade.includes(n.cidadeId))) {
                                                            return;
                                                        }
                                                    }

                                                    if (!selectedEstado.includes(0)) {
                                                        if (selectedEstado.length > 0 && !(selectedEstado.includes(n.estadoId))) {
                                                            return;
                                                        }
                                                    }

                                                    if (selectedFonte !== '' && selectedFonte !== 0 && n.fonteId !== selectedFonte) {
                                                        return;
                                                    }

                                                    if (selectedSituacao !== '' && selectedSituacao !== 0 && n.ativa.toString() !== (selectedSituacao == 1 ? 'true' : 'false')) {
                                                        return;
                                                    }

                                                    //var tempScale = ['#170D1E', '#2D1B34', '#43284D', '#543261', '#3E3061',
                                                    //    '#31326F', '#394586', '#4543A0', '#3E6391', '#487FA6', '#488CA7',
                                                    //    '#4593A0', '#4899A7', '#48A6A7', '#48A79A', '#49A781', '#49A774',
                                                    //    '#49A767', '#49A75A', '#49A744', '#51A749', '#7AA947', '#92A849',
                                                    //    '#9FA849', '#A8A449', '#A8974A', '#B38240', '#C56841', '#D05835',
                                                    //    '#DF1E1E', '#CB1C12', '#C10000', '#AA0000', '#8C0000', '#800000',
                                                    //    '#640000', '#420000', '#170000'];

                                                    //const tempValue = (selectedColumn == 'temperatura' ? (parseFloat(n.temperatura) + 12) : (selectedColumn == 'temperaturaMinima' ? (parseFloat(n.temperaturaMinima) + 12) : (selectedColumn == 'temperaturaMaxima' ? (parseFloat(n.temperaturaMaxima) + 12) : (parseFloat(n.parseFloat) + 12))));
                                                    //var scaleNumber = Math.round(tempValue / 1.5);
                                                    //var bgcolor = "#999";
                                                    //var seconds = (new Date() - new Date(n.dataAtualizacao).getTime()) / 1000;

                                                    //if (n.ativa) {
                                                    //    if (scaleNumber > (tempScale.length - 1)) {
                                                    //        scaleNumber = (tempScale.length - 1);
                                                    //    }
                                                    //    if (scaleNumber < 0) {
                                                    //        scaleNumber = 0;
                                                    //    }
                                                    //    bgcolor = tempScale[scaleNumber];
                                                    //}

                                                    //var img = imgTermometro_0_10;
                                                    //if (n.temperatura <= 10) {
                                                    //    img = imgTermometro_0_10;
                                                    //} else if (n.temperatura > 10 && n.temperatura <= 20) {
                                                    //    img = imgTermometro_10_20;
                                                    //} else if (n.temperatura > 20) {
                                                    //    img = imgTermometro_20_30;
                                                    //}

                                                    n.temperatura = parseFloat(n.temperatura).toFixed(1);
                                                    n.temperaturaMinima = parseFloat(n.temperaturaMinima).toFixed(1);
                                                    n.temperaturaMaxima = parseFloat(n.temperaturaMaxima).toFixed(1);
                                                    n.po = parseFloat(n.po).toFixed(1);
                                                    n.ventoVelocidade = parseFloat(n.ventoVelocidade).toFixed(1);

                                                    if (n.rajadaVentoMaxima) {
                                                        n.rajadaVentoMaxima = parseFloat(n.rajadaVentoMaxima).toFixed(1);
                                                    } else {
                                                        n.rajadaVentoMaxima = parseFloat(0).toFixed(1);
                                                    }

                                                    if (n.totalChuvaDia) {
                                                        n.totalChuvaDia = parseFloat(n.totalChuvaDia).toFixed(2);
                                                    } else {
                                                        n.totalChuvaDia = parseFloat(0).toFixed(2);
                                                    }

                                                    var location = '';
                                                    if (n.uf != null && n.cidadeNome != null) {
                                                        location = n.cidadeNome + ', ' + n.uf;
                                                    } else if (n.uf != null) {
                                                        location = n.uf;
                                                    } else if (n.cidadeNome != null) {
                                                        location = n.cidadeNome;
                                                    }

                                                    var nome = (n.apelido ? n.apelido : n.nome);
                                                    if (nome.length > 25) {
                                                        nome = nome.substring(0, 25);
                                                    }

                                                    //function displayTime(seconds) {
                                                    //    const format = val => `${Math.floor(val)}`.slice(-2)
                                                    //    const hours = seconds / 3600
                                                    //    const minutes = (seconds % 3600) / 60

                                                    //    if (format(hours) != '0' && format(minutes) != '0' && format(seconds) != '0') {
                                                    //        return format(hours) + 'h ' + format(minutes) + 'm ' + format(seconds % 60) + 's';
                                                    //    } else if (format(minutes) != '0' && format(seconds) != '0') {
                                                    //        return format(minutes) + 'm ' + format(seconds % 60) + 's';
                                                    //    } else if (format(seconds) != '0') {
                                                    //        return format(seconds % 60) + 's';
                                                    //    } else {
                                                    //        return '-';
                                                    //    }

                                                    //}

                                                    //var link = '';
                                                    //if (n.fonteId === 3) {
                                                    //    link = 'https://tempo.inmet.gov.br/GraficosDiarios/' + n.codigo;
                                                    //} else if (n.fonteId === 2) {
                                                    //    link = 'https://www.wunderground.com/dashboard/pws/' + n.codigo;
                                                    //} else if (n.link != null) {
                                                    //    link = n.link;
                                                    //}

                                                    return (
                                                        <TableRow key={n.id}>
                                                            <TableCell component="th" scope="row">
                                                                {nome}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {location}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {isNaN(n.temperatura) ? '' : n.temperatura}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {isNaN(n.temperaturaMinima) ? '' : n.temperaturaMinima}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {isNaN(n.temperaturaMaxima) ? '' : n.temperaturaMaxima}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {isNaN(n.ura) ? '' : n.ura}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {isNaN(n.po) ? '' : n.po}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {isNaN(n.chuva) ? '' : n.chuva}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {isNaN(n.totalChuvaDia) ? '' : n.totalChuvaDia}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {isNaN(n.pressao) ? '' : n.pressao}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {isNaN(n.ventoVelocidade) ? '' : n.ventoVelocidade}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {isNaN(n.rajadaVentoMaxima) ? '' : n.rajadaVentoMaxima}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                            }
                                        </TableBody>
                                    </Table>

                                </TableContainer>
                            </Grid>

                            : (view == 3 ?

                                <div className={classes.mapFrame}>
                                    <MarkerInfoWindow
                                        visualization={estacoes}
                                        selectedEstado={selectedEstado}
                                        selectedCidade={selectedCidade}
                                        selectedFonte={selectedFonte}
                                        selectedSituacao={selectedSituacao}
                                        selectedSituacao={selectedSituacao}
                                        selectedColumn={selectedColumn}
                                        selectedOrder={selectedOrder} />
                                </div>

                                :
                                <Grid container spacing={24}>
                                    <Paper className={[classes.root, classes.paperContainer].join(' ')}>
                                        <div className={classes.paperOpacity}>
                                            <div style={{ padding: 50 }}>
                                                Recurso disponível apenas para assinantes. Dados fictícios.                                                
                                            </div>
                                            <div style={{ padding: 10, marginTop: 10 }}>
                                                <p>Efetue a assinatura para prosseguir com a visualização. Por apenas R$ 8,25 por mês (R$ 99,00/ano), você tem acesso a filtros e estações de todo o Brasil. Para novos visitantes há a possibilidade <b>testar por 7 dias gratuitamente.</b></p>
                                            </div>
                                            <div style={{ marginTop: 15 }}>
                                                <Button variant="contained" color="secondary" className={classes.clearButton} onClick={() => history.push('/pagamentos')}>ASSINAR</Button>
                                            </div>
                                        </div>
                                    </Paper>
                                </Grid>
                            )

                        ))}

                </main>
            </div>
        </div>
    )
}

Visualization.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
    return {
        state
    };
}
const connectedVisualizationPage = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(withStyles(styles)(Visualization)));
export { connectedVisualizationPage as Visualization };